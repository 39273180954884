import React, { useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

import MemberList from "./MemberList";
// import BillingServicesContent from "./components/BillingServicesContent";

const GetAllMembers = () => {
  const { getAllMemberWiseReportData, initialState } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);

  // Retrieve page number from localStorage, default to 1 if not found
  // const storedPage = localStorage.getItem("currentPage");
  // const [currentPage, setCurrentPage] = useState(
  //   storedPage ? Number(storedPage) : 1
  // );

  // const storedPageSize = localStorage.getItem("pageSize");
  // const [pageSize, setPageSize] = useState(
  //   storedPageSize ? Number(storedPageSize) : 10
  // );

  // useEffect(() => {
  //   const handleRefresh = (event) => {
  //     setCurrentPage(1);
  //     setPageSize(10);
  //     localStorage.setItem("currentPage", 1);
  //     localStorage.setItem("pageSize", 10);
  //   };
  //   // Reset pagination on component mount
  //   setCurrentPage(1);
  //   setPageSize(10);
  //   localStorage.setItem("currentPage", 1);
  //   localStorage.setItem("pageSize", 10);
  //   window.addEventListener("beforeunload", handleRefresh);

  //   return () => {
  //     // Clear localStorage values when component unmounts
  //     localStorage.removeItem("currentPage");
  //     localStorage.removeItem("pageSize");
  //     window.addEventListener("beforeunload", handleRefresh);
  //   };
  // }, [currentPage]);

  // useEffect(() => {
  //   getAllMemberWiseReportData();
  // }, [isUpdated]);



  const [currentPage, setCurrentPage] = useState(Number(localStorage.getItem("currentPage")) || 1);
  const [pageSize, setPageSize] = useState(Number(localStorage.getItem("pageSize")) || 10);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("pageSize", pageSize);
    setCurrentPage(1); // Reset to first page when changing page size
  }, [pageSize]);
  return (
    <MemberList
      allMemberReports={initialState.allMemberReports}
      isLoading={initialState?.isLoading}
         setIsUpdated={setIsUpdated}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
    />
  );
};

export default GetAllMembers;
// const GetAllMembers = () => {
//   const { initialState } = useContext(ContextAPI);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [isUpdated, setIsUpdated] = useState(false);
//   useEffect(() => {
//     const storedPage = localStorage.getItem("currentPage");
//     const storedPageSize = localStorage.getItem("pageSize");

//     if (storedPage) {
//       setCurrentPage(Number(storedPage));
//     }
//     if (storedPageSize) {
//       setPageSize(Number(storedPageSize));
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("currentPage", currentPage);
//   }, [currentPage]);

//   useEffect(() => {
//     localStorage.setItem("pageSize", pageSize);
//   }, [pageSize]);

//   const handlePageChange = (newPage) => {
//     if (newPage > 0) {
//       setCurrentPage(newPage);
//     }
//   };

//   return (
//     <MemberList
//       allMemberReports={initialState.allMemberReports}
//       isLoading={initialState?.isLoading}
//       currentPage={currentPage}
//       setIsUpdated={setIsUpdated}
//       setCurrentPage={handlePageChange} // Pass the handler to update page
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//     />
//   );
// };

// export default GetAllMembers;