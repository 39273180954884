import React, { useContext } from "react";
import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

const TimeEntriesTable = ({
  tableInstance,
  headers,
  timeEntriesData,
  columnHeaders,
}) => {
  const { userDetails } = useContext(ContextAPI);
  return (
    <section className="time-entries-table mt-4 ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} columnHeaders={columnHeaders} />
      {userDetails?.member_role === "it_member" ||
      userDetails?.member_role === "operation_member" ? (
        <ReactTableFooter
          headers={headers}
          data={timeEntriesData}
          tableInstance={tableInstance}
          text="ItAndOprationTimeEntry"
        />
      ) : (
        <ReactTableFooter
          headers={headers}
          data={timeEntriesData}
          tableInstance={tableInstance}
          text="MembersTimeEntry"
        />
      )}
    </section>
  );
};

export default TimeEntriesTable;
