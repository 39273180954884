// import React, { useState, useEffect, useContext } from "react";

// import TeamsContent from "./components/TeamsContent";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

// const AdminOrManagerTeams = () => {
//   const { getAllTeams, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all teams API
//   useEffect(() => {
//     getAllTeams();
//   }, [isUpdated]);

//   return (
//     <TeamsContent
//       teamsData={initialState.teamsList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };

// export default AdminOrManagerTeams;

import React, { useState, useEffect, useContext } from "react";

import TeamsContent from "./components/TeamsContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

const AdminOrManagerTeams = () => {
  const { getAllTeams, initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );
  const [searchTriggered, setSearchTriggered] = useState(false);
  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value.trim());
  };

  const handleSearch = () => {
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchInput === "" && !searchTriggered) {
      getAllTeams(currentPage, pageSize, "");
    } else if (searchTriggered && searchInput !== "") {
      getAllTeams(currentPage, pageSize, searchInput);
    }
  }, [currentPage, pageSize, searchInput, searchTriggered,isUpdated]);

  // fetching all teams API
  // useEffect(() => {
  //   getAllTeams();
  // }, [isUpdated]);

  return (
    <TeamsContent
      teamsData={initialState?.teamsList}
      setIsUpdated={setIsUpdated}
      isLoading={initialState.isLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleSearch={handleSearch}
      handleInputChange={handleInputChange}
    />
  );
};

export default AdminOrManagerTeams;
