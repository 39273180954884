import React, { useContext, useEffect } from "react";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { useLocation } from "react-router-dom";
import { SendInvoiceModal } from "../../Invoices/Components/SendInvoiceModal";
import { SendMailAdvanceBilling } from "../../AdvanceBilling/components/SendMailAdvanceBilling";
// import {
//   SendMailAdvance,
//   SendMailAdvanceBilling,
// } from "./SendMailAdvanceBilling";
const AdHocBillingInvoice = ({ url }) => {
  const { getAllAdvanceBillingJobs, initialState, mainURL } =
    useContext(ContextAPI);
  const location = useLocation();
  const { invoice_url, row, assignId, clientEmail ,adHocMail} = location.state || {};
  const { sidebarClose } = useContext(ContextSidebarToggler);

  useEffect(() => {
    getAllAdvanceBillingJobs();
  }, []);
  const breadCrumbs = [
    {
      pageName: "Adv Billing Jobs",
      pageURL: "/advance-billing",
    },
    {
      pageName: "Adv Billing Invoice",
    },
  ];
  const breadcrumbs = location.state?.breadcrumbs || breadCrumbs;
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>
      <div class="d-flex justify-content-end  " style={{ marginRight: "30px" }}>
        <SendMailAdvanceBilling
          invoiceUrl={invoice_url}
          assignId={assignId}
          row={row}
          adHocMail={adHocMail}
          clientEmail={clientEmail}
        />
      </div>

      <div
        className="invoice-job"
        style={{ padding: "20px", textAlign: "center" }}
      >
        {invoice_url ? (
          <>
            <iframe
              src={invoice_url}
              style={{
                width: "100%",
                height: "700px",
                border: "none",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            />
          </>
        ) : (
          <p>No invoice available to display.</p>
        )}
      </div>
    </div>
  );
};

export default AdHocBillingInvoice;
