// import React, { useContext, useState, useEffect } from "react";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import AdvancBillingContent from "./AdvanceBillingContent";
// import AdvancBillingJobListContent from "../AdvanceBillingJobList.js/components/AdvanceBillingJobListContent";

// export const AdvancBillingJobs = () => {
//   const { getAllAdvanceBillingJobs, getJobsDetailsByMemberId, initialState } =
//     useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   useEffect(() => {
//     // getJobsDetailsByMemberId()
//     getAllAdvanceBillingJobs();
//   }, [isUpdated]);

//   return (
//     <>
//       <AdvancBillingContent
//         advancBillingJobsData={initialState?.advanceBillingJobList}
//         setIsUpdated={setIsUpdated}
//         isLoading={initialState?.isLoading}
//       />
//       {/* <AdvancBillingJobListContent
//     advancBillingJobsData={initialState?.advanceBillingJobList}
//     setIsUpdated={setIsUpdated}
//     isLoading={initialState?.isLoading}
//   /> */}
//     </>
//   );
// };
import React, { useContext, useState, useEffect } from "react";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdvancBillingContent from "./AdvanceBillingContent";
import AdvancBillingJobListContent from "../AdvanceBillingJobList.js/components/AdvanceBillingJobListContent";

export const AdvancBillingJobs = () => {
  const { getAllAdvanceBillingJobs, getJobsDetailsByMemberId, initialState } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllAdvanceBillingJobs(currentPage, pageSize);
    }, 100);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [isUpdated, currentPage, pageSize]);

  return (
    <>
      <AdvancBillingContent
        advancBillingJobsData={initialState?.advanceBillingJobList}
        setIsUpdated={setIsUpdated}
        isLoading={initialState?.isLoading}
        totalPages={initialState?.totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
