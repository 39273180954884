// import React, { useState } from "react";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";
// import { format, parseISO, isValid } from "date-fns";
// import {
//   EyeIconWithCircle,
//   InvoiceIcon,
//   reportsIcon,
// } from "../../../../utils/ImportingImages/ImportingImages";
// import { InvoiceSendMail } from "./InvoiceSendMail";
// import styles from "./Invoice.module.css";

// const MyVerticallyCenteredModal = (props) => {
//   const { clientData } = props;
//   const [filters, setFilters] = useState({
//     status: null,
//   });
//   const [showSendMailModal, setShowSendMailModal] = useState(false);
//   const [activeTab, setActiveTab] = useState("invoice");
//   const [selectedJobs, setSelectedJobs] = useState([]);

//   const [selectAll, setSelectAll] = useState(false);
//   const statusOptions = [
//     { label: "Unpaid", value: "1" },
//     { label: "Partially Paid", value: "2" },
//     { label: "Paid", value: "3" },
//   ];

//   const handleSelectAllChange = () => {
//     if (selectAll) {
//       setSelectedJobs([]);
//     } else {
//       const allJobIds = props.clientData.invoices
//         .concat(
//           props.clientData.advance_billing,
//           props.clientData.adhoc_billing
//         )
//         .filter((job) => job.payment_status !== "paid")
//         .map((job) => job.id || job.invoice_id);
//       setSelectedJobs(allJobIds);
//     }
//     setSelectAll(!selectAll);
//   };

//   const handleCheckboxChange = (jobId) => {
//     setSelectedJobs((prevSelected) => {
//       const newSelected = prevSelected.includes(jobId)
//         ? prevSelected.filter((id) => id !== jobId)
//         : [...prevSelected, jobId];

//       const totalJobs = props.clientData.invoices
//         .concat(
//           props.clientData.advance_billing,
//           props.clientData.adhoc_billing
//         )
//         .filter((job) => job.payment_status !== "paid").length;

//       setSelectAll(newSelected.length === totalJobs);
//       return newSelected;
//     });
//   };

//   const renderTable = () => {
//     let jobs = [];
//     switch (activeTab) {
//       case "invoice":
//         jobs = props.clientData.invoices;
//         break;
//       case "advanceBilling":
//         jobs = props.clientData.advance_billing;
//         break;
//       case "adhocBilling":
//         jobs = props.clientData.adhoc_billing;
//         break;
//       default:
//         break;
//     }
//     if (filters.status) {
//       jobs = jobs.filter((job) => {
//         switch (filters.status.value) {
//           case "1":
//             return job.payment_status === "unpaid";
//           case "2":
//             return job.payment_status === "partial_paid";
//           case "3":
//             return job.payment_status === "paid";
//           default:
//             return true; // No filter applied
//         }
//       });
//     }
//     const getStatusBadge = (status) => {
//       switch (status.toLowerCase()) {
//         case "unpaid":
//           return <Badge bg="danger">Unpaid</Badge>;
//         case "partial_paid":
//           return <Badge bg="warning">Partially Paid</Badge>;
//         case "paid":
//           return <Badge bg="success">Paid</Badge>;
//         default:
//           return <Badge bg="secondary">N/A</Badge>;
//       }
//     };
//     const selectableJobs = jobs.filter((job) => job.payment_status !== "paid");

//     return (
    
//       <div class={styles.gridtable}>
//         <table className="table table-bordered table-hover mt-2 w-100">
//           <thead>
//             <tr>
//               <th style={{ textAlign: "center",width:"50px" }} >
//                 {selectableJobs.length > 0 ? (
//                   <input
//                     type="checkbox"
//                     checked={selectAll}
//                     onChange={handleSelectAllChange}
//                   />
//                 ) : null}
//               </th>

//               <th>Invoice Code</th>
//               <th>Description</th>
//               <th>Amount</th>
//               <th>Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Array.isArray(jobs) && jobs.length > 0 ? (
//               jobs.map((item) => (
//                 <tr key={item.id}>
//                   <td style={{ textAlign: "center",width:"50px" }} >
//                     {item.payment_status !== "paid" && (
//                       <input
//                         type="checkbox"
//                         checked={selectedJobs.includes(
//                           item.id || item.invoice_id
//                         )}
//                         onChange={() =>
//                           handleCheckboxChange(item.invoice_id || item.id)
//                         }
//                       />
//                     )}
//                   </td>
//                   <td>
//                     {item.id
//                       ? item.invoice_code || "N/A"
//                       : item.invoice_id
//                         ? item.invoice_no || "N/A"
//                         : "N/A"}
//                   </td>

//                   <td>{item.job_description || "N/A"}</td>
//                   <td>{item.total_amount || "N/A"}</td>
//                   <td>{getStatusBadge(item.payment_status)}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="6">No records found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//     );
//   };
//   const resetSelectedJobs = () => {
//     setSelectedJobs([]);
//     setSelectAll(false);
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="xl"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <span className="modal-title">Client's Invoice</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body" >
//         <div className=" mt-3 mb-15 d-flex justify-content-start align-items-center gap-3" >
//           <div className="relative-wrapper">
//             <img className="search-icon" src={reportsIcon} alt="search-icon" />
//             <Select
//               closeMenuOnSelect={true}
//               isClearable={true}
//               options={statusOptions}
//               onChange={(option) => setFilters({ status: option })}
//               value={filters.status}
//               placeholder="Select status"
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           <button
//             className={`btn ${activeTab === "invoice" ? "btn-light" : "btn-secondary"
//               }`}
//             style={{
//               backgroundColor: activeTab === "invoice" ? "#00263d" : "",
//               color: activeTab === "invoice" ? "white" : "",
//               fontWeight: "500",
//             }}
//             onClick={() => setActiveTab("invoice")}
//           >
//             Regular Invoice
//           </button>
//           <button
//             className={`btn ${activeTab === "advanceBilling" ? "btn-light" : "btn-secondary"
//               }`}
//             style={{
//               backgroundColor: activeTab === "advanceBilling" ? "#00263d" : "",
//               color: activeTab === "advanceBilling" ? "white" : "",
//               fontWeight: "500",
//             }}
//             onClick={() => setActiveTab("advanceBilling")}
//           >
//             Advanced Billing Invoice
//           </button>
//           <button
//             className={`btn ${activeTab === "adhocBilling" ? "btn-light" : "btn-secondary"
//               }`}
//             style={{
//               backgroundColor: activeTab === "adhocBilling" ? "#00263d" : "",
//               color: activeTab === "adhocBilling" ? "white" : "",
//               fontWeight: "500",
//             }}
//             onClick={() => setActiveTab("adhocBilling")}
//           >
//             Ad Hoc Billing Invoice
//           </button>
//           {selectedJobs.length > 0 && (
//             <InvoiceSendMail
//               show={showSendMailModal}
//               onHide={() => {
//                 setShowSendMailModal(false);
//                 resetSelectedJobs();
//               }}
//               invoiceIds={selectedJobs}
//               clientId={clientData.client_id}
//               activeTab={activeTab}
//               emailOption={clientData.email}
//               resetSelectedJobs={resetSelectedJobs}
//             />
//           )}
//         </div>

//         <div className="w-100" >{renderTable()}</div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const ClientWiseInvoiceListModal = ({
//   clientData,
//   setIsUpdated,
//   emailOption,
//   resetSelectedJobs,
// }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <div
//         onClick={() => {
//           setModalShow(true);
//         }}
//       >
//         <div className="">
//           <InvoiceIcon />
//         </div>
//       </div>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         clientData={clientData}
//         setIsUpdated={setIsUpdated}
//         emailOption={emailOption}
//         resetSelectedJobs={resetSelectedJobs}
//       />
//     </>
//   );
// };
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Badge from "react-bootstrap/Badge";
import { InvoiceSendMail } from "./InvoiceSendMail";
import styles from "./Invoice.module.css";
import {
    EyeIconWithCircle,
    InvoiceIcon,
    reportsIcon,
  } from "../../../../utils/ImportingImages/ImportingImages";

const MyVerticallyCenteredModal = (props) => {
  const { clientData } = props;
  const [filters, setFilters] = useState({ status: null });
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [activeTab, setActiveTab] = useState("invoice");
  const [selectedJobsByTab, setSelectedJobsByTab] = useState({
    invoice: [],
    advanceBilling: [],
    adhocBilling: [],
  });

  const [selectAll, setSelectAll] = useState(false);
  const statusOptions = [
    { label: "Unpaid", value: "1" },
    { label: "Partially Paid", value: "2" },
    { label: "Paid", value: "3" },
  ];

  const handleSelectAllChange = () => {
    const currentTabJobs = getJobsByTab(activeTab).filter(
      (job) => job.payment_status !== "paid"
    );
    if (selectAll) {
      setSelectedJobsByTab((prev) => ({
        ...prev,
        [activeTab]: [],
      }));
    } else {
      const allJobIds = currentTabJobs.map((job) => job.id || job.invoice_id);
      setSelectedJobsByTab((prev) => ({
        ...prev,
        [activeTab]: allJobIds,
      }));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (jobId) => {
    setSelectedJobsByTab((prev) => {
      const newSelected = prev[activeTab].includes(jobId)
        ? prev[activeTab].filter((id) => id !== jobId)
        : [...prev[activeTab], jobId];

      return { ...prev, [activeTab]: newSelected };
    });
  };

  const getJobsByTab = (tab) => {
    switch (tab) {
      case "invoice":
        return clientData.invoices;
      case "advanceBilling":
        return clientData.advance_billing;
      case "adhocBilling":
        return clientData.adhoc_billing;
      default:
        return [];
    }
  };

  const renderTable = () => {
    let jobs = getJobsByTab(activeTab);
    if (filters.status) {
      jobs = jobs.filter((job) => {
        switch (filters.status.value) {
          case "1":
            return job.payment_status === "unpaid";
          case "2":
            return job.payment_status === "partial_paid";
          case "3":
            return job.payment_status === "paid";
          default:
            return true; // No filter applied
        }
      });
    }

    return (
      <div className={styles.gridtable}>
        <table className="table table-bordered table-hover mt-2 w-100">
          <thead>
            <tr>
              <th style={{ textAlign: "center", width: "50px" }}>
                {jobs.filter((job) => job.payment_status !== "paid").length > 0 && (
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                )}
              </th>
              <th>Invoice Code</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {jobs.length > 0 ? (
              jobs.map((item) => (
                <tr key={item.id}>
                  <td style={{ textAlign: "center", width: "50px" }}>
                    {item.payment_status !== "paid" && (
                      <input
                        type="checkbox"
                        checked={selectedJobsByTab[activeTab].includes(item.id || item.invoice_id)}
                        onChange={() => handleCheckboxChange(item.id || item.invoice_id)}
                      />
                    )}
                  </td>
                  <td>{item.invoice_code || item.invoice_no || "N/A"}</td>
                  <td>{item.job_description || "N/A"}</td>
                  <td>{item.total_amount || "N/A"}</td>
                  <td>
                    <Badge bg={getStatusBadge(item.payment_status)}  style={{ display: 'inline-block', textAlign: 'center' }}>{item.payment_status}</Badge>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const getStatusBadge = (status) => {
    switch (status.toLowerCase()) {
      case "unpaid":
        return "danger";
      case "partial_paid":
        return "warning";
      case "paid":
        return "success";
      default:
        return "secondary";
    }
  };

  const resetSelectedJobs = () => {
    setSelectedJobsByTab({
      invoice: [],
      advanceBilling: [],
      adhocBilling: [],
    });
    setSelectAll(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Client's Invoice</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
      <div className=" mt-3 mb-15 d-flex justify-content-start align-items-center gap-2" >
          <div className="relative-wrapper">
            <img className="search-icon" src={reportsIcon} alt="search-icon" /><Select
              closeMenuOnSelect={true}
              isClearable={true}
              options={statusOptions}
              onChange={(option) => setFilters({ status: option })}
              value={filters.status}
              placeholder="Select status"
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <button
            className={`btn ${activeTab === "invoice" ? "btn-light" : "btn-secondary"
              }`}
            style={{
              backgroundColor: activeTab === "invoice" ? "#00263d" : "",
              color: activeTab === "invoice" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => setActiveTab("invoice")}
          >
            Regular Invoice
          </button>
          <button
            className={`btn ${activeTab === "advanceBilling" ? "btn-light" : "btn-secondary"
              }`}
            style={{
              backgroundColor: activeTab === "advanceBilling" ? "#00263d" : "",
              color: activeTab === "advanceBilling" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => setActiveTab("advanceBilling")}
          >
            Advanced Billing Invoice
          </button>
          <button
            className={`btn ${activeTab === "adhocBilling" ? "btn-light" : "btn-secondary"
              }`}
            style={{
              backgroundColor: activeTab === "adhocBilling" ? "#00263d" : "",
              color: activeTab === "adhocBilling" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => setActiveTab("adhocBilling")}
          >
            Ad Hoc Billing Invoice
          </button>
          {selectedJobsByTab[activeTab].length > 0 && (
            <InvoiceSendMail
              show={showSendMailModal}
              onHide={() => {
                setShowSendMailModal(false);
                resetSelectedJobs();
              }}
              invoiceIds={selectedJobsByTab[activeTab]}
              clientId={clientData.client_id}
              activeTab={activeTab}
              emailOption={clientData.email}
              resetSelectedJobs={resetSelectedJobs}
            />
          )}
        </div>

        <div className="w-100">{renderTable()}</div>
      </Modal.Body>
    </Modal>
  );
};

export const ClientWiseInvoiceListModal = ({
  clientData,
  setIsUpdated,
  emailOption,
  resetSelectedJobs,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <InvoiceIcon />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        clientData={clientData}
        setIsUpdated={setIsUpdated}
        emailOption={emailOption}
        resetSelectedJobs={resetSelectedJobs}
      />
    </>
  );
};
