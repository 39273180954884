import React from "react";

import ReactTable from "../../../../templates/ReactTable";
import ReactTableFooter from "../../../../templates/ReactTableFooter";

const AdvanceBillingReportTable = ({
  tableInstance,
  headers,
  reportsData,
  advancebillingReportData,
  columnHeaders,
  teamWiseList,
}) => {
  return (
    <section className="ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} columnHeaders={columnHeaders} />
      <ReactTableFooter
        headers={headers}
        data={advancebillingReportData}
        // data={reportsData}
        // data={teamWiseList}
        tableInstance={tableInstance}
      />
    </section>
  );
};

export default AdvanceBillingReportTable;
