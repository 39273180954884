import React from "react";
import styles from "./Invoices.module.css";

const InvoiceHeader = ({ invoice ,invoiceNo}) => {
  const getTodayDate = () => {
    const date = new Date();
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  const displayDate = getTodayDate();
  return (
    <div className="card-header p-4">
      <img
        src="https://doshipms.com/static/media/LOGO.f8ced44d6d0298f50f0c.png"
        className={styles.logo}
        alt="logo"
      />

      <div className={`pt-3 float-right`}>
        <h5 className={`mb-0 font-outfit primary-font-color`}>
          Invoice: {invoiceNo}
        </h5>
        <p className={`mb-0 font-outfit primary-font-color`}>
          Date: {displayDate} 

        </p>
      </div>
    </div>
  );
};

export default InvoiceHeader;
