// import React, { useContext, useMemo, useEffect, useState } from "react";
// import { Tooltip } from "react-tooltip";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";

// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";

// import { fileIcon } from "../../../../utils/ImportingImages/ImportingImages";
// import PageHeader from "../../../../templates/PageHeader";
// import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../../templates/Breadcrumbs";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
// import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
// import TimeEntriesTable from "../../Components/TimeEntriesTable";
// import { UpdateTimeEntryModal } from "../../AdminOrManager/TimeEntries/components/UpdateTimeEntryModal";
// import TimeEntriesFilter from "../../Components/TimeEntriesFilter";
// import {
//   formatDate,
//   formatTime,
// } from "../../../../utils/utilities/utilityFunctions";
// import { SelectedMultipleEntriesModal } from "../../Components/SelectedMultipleEntriesModal";

// const userRole = localStorage.getItem("userRole");

// const breadCrumbs = [
//   {
//     pageName: "Home",
//     pageURL: "/dashboard",
//   },
//   {
//     pageName: "Jobs",
//     pageURL: "/jobs",
//   },
//   {
//     pageName: "Time Entries",
//     pageURL: "/jobs/time-entries",
//   },
// ];

// const columnHeaders = [
//   "Sr no.",
//   "Member",
//   "Job",
//   "Team",
//   "Reviewer",
//   "Status",
//   "Description",
//   "Working Date",
//   "Edit",
// ];

// // constructing headers for CSV Link
// const headers = {
//   headings: [
//     { label: "Job", key: "task_name" },
//     { label: "Member", key: "member_name" },
//     { label: "Team", key: "team_name" },
//     { label: "Reviewer", key: "reviewer_name" },
//     { label: "Description", key: "work_description" },
//     { label: "Status", key: "time_entries_status" },
//     { label: "Date", key: "working_date" },
//     { label: "Time", key: "working_time" },
//   ],
//   fileName: "Time Entries",
// };

// const MyTeamTimeEntriesContent = ({
//   setIsUpdated,
//   isLoading,
//   fetchedTimeEntriesListTeam,
//   currentPage,
//   setCurrentPage,
//   pageSize,
//   totalPages,
//   setPageSize,
// }) => {
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const { getAllMyTeamTimeEntries, getTeamDetailsByMemberId, initialState } =
//     useContext(ContextAPI);
//   // const [isUpdated, setIsUpdated] = useState(false);
//   const [timeEntriesData, setTimeEntriesData] = useState([]);
//   const [filters, setFilters] = useState({
//     status: null,
//     entryAs: null,
//   });

//   // useEffect(() => {
//   //   getTeamDetailsByMemberId();
//   // }, []);

//   // useEffect(() => {
//   //   if (initialState.myTeams) {
//   //     getAllMyTeamTimeEntries(initialState?.myTeams?.id);
//   //   }
//   // }, [initialState.myTeams]);

//   // useEffect(() => {
//   //   if (initialState.myTeams && initialState.myTeams.id) {
//   //     const fetchTimeEntries = async () => {
//   //       try {
//   //         await getAllMyTeamTimeEntries(initialState.myTeams.id);
//   //       } catch (error) {
//   //         console.error("Failed to fetch time entries:", error);
//   //       }
//   //     };
//   //     fetchTimeEntries();
//   //   }
//   // }, [initialState.myTeams?.id, setIsUpdated]);

//   useEffect(() => {
//     const filterByStatus =
//       fetchedTimeEntriesListTeam &&
//       fetchedTimeEntriesListTeam?.filter((entry) =>
//         filters.status
//           ? entry.time_entries_status === filters.status.value
//           : entry
//       );
//     const filterByEntryAs = filterByStatus?.filter((entry) =>
//       filters.entryAs ? entry.entries_as === filters.entryAs.value : entry
//     );

//     sortByDesc(filterByEntryAs);
//   }, [filters, fetchedTimeEntriesListTeam]);

//   const handleCheckbox = (e) => {
//     const { name, checked } = e.target;

//     // checking all time entries
//     if (name === "allTimeEntries") {
//       const checkingAllPendingTimeEntries = timeEntriesData.map((entry) =>
//         entry.time_entries_status === "pending"
//           ? {
//               ...entry,
//               isChecked: checked,
//             }
//           : entry
//       );
//       sortByDesc(checkingAllPendingTimeEntries);
//     } else {
//       const checkSelectedEntry = timeEntriesData.map((entry) =>
//         entry.entries_id === name ? { ...entry, isChecked: checked } : entry
//       );
//       sortByDesc(checkSelectedEntry);
//     }
//   };

//   // checking if all entries are approved or not
//   const allEntriesApproved = useMemo(
//     () =>
//       timeEntriesData.every(
//         (entry) => entry.time_entries_status === "approved"
//       ),
//     [timeEntriesData]
//   );

//   // checking "all time entries" checkbox if all pending entries are selected / main checkbox is selected
//   const checkAllPendingTimeEntriesCheckbox = useMemo(() => {
//     const temp = timeEntriesData
//       .filter((entry) => entry.time_entries_status === "pending")
//       .every((entry) => entry?.isChecked);

//     return temp;
//   }, [timeEntriesData]);

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "entries_id",
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       Header: "Member",
//       accessor: "member_name",
//     },
//     // {
//     //   Header: "Job",
//     //   accessor: "task_name",
//     // },
//     {
//       Header: "Job",
//       accessor: "task_name",
//       Cell: ({ row }) => row.original.task_name || "Side Work",
//     },
//     {
//       Header: "Team",
//       accessor: "team_name",
//       Cell: ({ row }) => row.original.team_name ?? "---",
//     },
//     {
//       Header: "Reviewer",
//       accessor: "reviewer_name",
//       Cell: ({ row }) => row.original.reviewer_name ?? "---",
//     },
//     {
//       Header: "Status",
//       accessor: "time_entries_status",
//       Cell: ({ row }) => (
//         <div className="d-flex justify-content-start">
//           <Stack direction="horizontal">
//             {row.original.time_entries_status === "approved" ? (
//               <Badge bg="success">Approved</Badge>
//             ) : (
//               <Badge bg="danger">Pending</Badge>
//             )}
//           </Stack>
//         </div>
//       ),
//     },
//     {
//       Header: "Working Date",
//       accessor: "working_date",
//       Cell: ({ row }) => {
//         const date = formatDate(row.original.working_date);
//         const time = formatTime(row.original.working_time);
//         return (
//           <div className="d-flex flex-column justify-content-start gap-1">
//             <p className="m-0">{date}</p>
//             <p className="m-0">{time}</p>
//           </div>
//         );
//       },
//     },
//     {
//       Header: "Description",
//       accessor: "work_description",
//     },
//   ];

//   // Conditionally add Edit column based on userRole
//   if (userRole !== "members,team_sub_leader") {
//     tableColumns.push({
//       Header: "Edit",
//       Cell: ({ row }) => (
//         <>
//           {row.original.time_entries_status === "pending" && (
//             <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
//               <Tooltip
//                 id="edit-time-entry-tooltip"
//                 style={{
//                   background: "#000",
//                   color: "#fff",
//                 }}
//                 opacity={0.9}
//               />
//               <div
//                 data-tooltip-id="edit-time-entry-tooltip"
//                 data-tooltip-content="Update Time Entry"
//                 data-tooltip-place="top"
//               >
//                 <UpdateTimeEntryModal
//                   setIsUpdated={setIsUpdated}
//                   timeEntryData={row.original}
//                 />
//               </div>
//             </div>
//           )}
//         </>
//       ),
//     });
//   }

//   const columns = useMemo(() => {
//     if (!allEntriesApproved) {
//       const checkboxColumn = {
//         Header: () => (
//           <input
//             type="checkbox"
//             checked={checkAllPendingTimeEntriesCheckbox}
//             name="allTimeEntries"
//             onChange={(e) => handleCheckbox(e)}
//             className="cursor-pointer checkbox-input"
//           />
//         ),
//         id: "selection_id",
//         Cell: ({ row }) =>
//           row.original.time_entries_status === "pending" && (
//             <input
//               type="checkbox"
//               onChange={(e) => handleCheckbox(e)}
//               name={row.original.entries_id}
//               checked={row.original?.isChecked || false}
//               className="cursor-pointer checkbox-input"
//             />
//           ),
//       };
//       return [checkboxColumn, ...tableColumns];
//     } else {
//       return tableColumns;
//     }
//   }, [timeEntriesData, allEntriesApproved]);

//   const data = useMemo(() => timeEntriesData, [timeEntriesData]);

//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageSize: pageSize },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );
//   const sortByDesc = (data) => {
//     if (data.length > 0) {
//       const sortedData = data.sort((a, b) => {
//         if (
//           a.time_entries_status === "pending" &&
//           b.time_entries_status !== "pending"
//         ) {
//           return -1;
//         }
//         if (
//           a.time_entries_status !== "pending" &&
//           b.time_entries_status === "pending"
//         ) {
//           return 1;
//         }
//         return new Date(b.working_date) - new Date(a.working_date);
//       });

//       setTimeEntriesData(sortedData);
//     } else {
//       setTimeEntriesData([]);
//     }
//   };
//   // const sortByDesc = (data) => {
//   //   if (data.length > 0) {
//   //     const sortedData = data.toSorted(
//   //       (a, b) => new Date(b.working_date) - new Date(a.working_date)
//   //     );
//   //     setTimeEntriesData(() => sortedData);
//   //   } else {
//   //     setTimeEntriesData(() => []);
//   //   }
//   // };

//   const selectedTimeEntries = useMemo(() => {
//     const isTrue = timeEntriesData.some((entry) => entry?.isChecked);
//     return isTrue;
//   }, [timeEntriesData]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     localStorage.setItem("currentPage", page); // Save the page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch
//   };

//   const handlePageSizeChange = (event) => {
//     const newSize = Number(event.target.value);
//     setPageSize(newSize); // Update the component's state
//     tableInstance.setPageSize(newSize); // Update the React Table's internal state
//     setCurrentPage(1); // Reset to the first page
//     localStorage.setItem("pageSize", newSize); // Save the page size
//     localStorage.setItem("currentPage", 1); // Save the new page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
//   };

//   useEffect(() => {
//     tableInstance.setPageSize(pageSize);
//   }, [pageSize, tableInstance]);
//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       <PageHeader
//         tableInstance={tableInstance}
//         icon={fileIcon}
//         headerTitle={"Time Entries"}
//       />

//       <TimeEntriesFilter
//         filters={filters}
//         setFilters={setFilters}
//         forTeamLeader={true}
//       />

//       {/* Approving Multiple Time Entries */}
//       {selectedTimeEntries && (
//         <div className="mr-40 ml-30 mt-2">
//           <SelectedMultipleEntriesModal
//             timeEntriesData={timeEntriesData}
//             setIsUpdated={setIsUpdated}
//           />
//         </div>
//       )}

//       {/* Time Entries Table */}
//       {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : timeEntriesData?.length > 0 ? (
//         <>
//           <TimeEntriesTable
//             tableInstance={tableInstance}
//             headers={headers}
//             timeEntriesData={timeEntriesData}
//             columnHeaders={columnHeaders}
//           />

//           <div
//             className="d-flex justfy-content-center align-items-center gap-3 smallText"
//             style={{
//               width: "45%",
//               justifyContent: "space-between",
//               position: "absolute",
//               marginLeft: "90px",
//               bottom: "48px",
//             }}
//           >
//             <div className="d-flex gap-1 align-items-center">
//               <button
//                 className="cursor-pointer"
//                 style={{ border: "none", background: "transparent" }}
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
//                   />
//                 </svg>
//               </button>
//               <input
//                 className="gotoPage-input-field"
//                 type="number"
//                 defaultValue={currentPage}
//                 readOnly
//               />
//               <span className="px-2">/</span>

//               <span> {totalPages}</span>
//               <button
//                 style={{ border: "none", background: "transparent" }}
//                 className="cursor-pointer"
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={
//                   fetchedTimeEntriesListTeam?.length < pageSize ||
//                   totalPages === 1
//                 }
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
//                   />
//                 </svg>
//               </button>
//             </div>
//             <div className="d-flex justify-content-center align-items-center">
//               <span className="px-1 smallText">Rows /page</span>
//               <select value={pageSize} onChange={handlePageSizeChange}>
//                 <option value={10}>10</option>
//                 <option value={25}>25</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//               </select>
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="mt-5 mr-40 ml-30 mb-15">
//           <h5>No Entries found by your team!</h5>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MyTeamTimeEntriesContent;


import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { fileIcon } from "../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import TimeEntriesTable from "../../Components/TimeEntriesTable";
import { UpdateTimeEntryModal } from "../../AdminOrManager/TimeEntries/components/UpdateTimeEntryModal";
import TimeEntriesFilter from "../../Components/TimeEntriesFilter";
import {
  formatDate,
  formatTime,
} from "../../../../utils/utilities/utilityFunctions";
import { SelectedMultipleEntriesModal } from "../../Components/SelectedMultipleEntriesModal";

const userRole = localStorage.getItem("userRole");

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Jobs",
    pageURL: "/jobs",
  },
  {
    pageName: "Time Entries",
    pageURL: "/jobs/time-entries",
  },
];

const columnHeaders = [
  "Sr no.",
  "Member",
  "Job",
  "Team",
  "Reviewer",
  "Status",
  "Description",
  "Working Date",
  "Edit",
];

// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Job", key: "task_name" },
    { label: "Member", key: "member_name" },
    { label: "Team", key: "team_name" },
    { label: "Reviewer", key: "reviewer_name" },
    { label: "Description", key: "work_description" },
    { label: "Status", key: "time_entries_status" },
    { label: "Date", key: "working_date" },
    { label: "Time", key: "working_time" },
  ],
  fileName: "Time Entries",
};

const MyTeamTimeEntriesContent = ({
  setIsUpdated,
  isLoading,
  fetchedTimeEntriesListTeam,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [timeEntriesData, setTimeEntriesData] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState(new Map());
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    if (fetchedTimeEntriesListTeam) {
      const updatedData = fetchedTimeEntriesListTeam.map((entry) => ({
        ...entry,
        isChecked: selectedEntries.has(entry.entries_id),
      }));
      sortByDesc(updatedData);
    }
  }, [fetchedTimeEntriesListTeam, selectedEntries]);

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;

    if (name === "allTimeEntries") {
      const newSelectedEntries = new Map(selectedEntries);

      timeEntriesData.forEach((entry) => {
        if (entry.time_entries_status === "pending") {
          if (checked) {
            newSelectedEntries.set(entry.entries_id, entry);
          } else {
            newSelectedEntries.delete(entry.entries_id);
          }
        }
      });

      setSelectedEntries(newSelectedEntries);
    } else {
      const newSelectedEntries = new Map(selectedEntries);
      const entry = timeEntriesData.find((entry) => entry.entries_id === name);

      if (checked) {
        newSelectedEntries.set(name, entry);
      } else {
        newSelectedEntries.delete(name);
      }

      setSelectedEntries(newSelectedEntries);
    }
  };
  // const handleLocalUpdate = (updatedEntries, isApproved) => {
  //   console.log("updatedEntriesGFGERGDG",updatedEntries);

  //   const newStatus = isApproved
  //   console.log("IS apppove data",newStatus);

  //   setTimeEntriesData(prevData => {
  //     console.log("perview data onn aa",prevData);

  //     return prevData.map(entry => {
  //       if (updatedEntries.some(updatedEntry => updatedEntry.entries_id === entry.entries_id)) {
  //         return {
  //           ...entry,
  //           time_entries_status: newStatus
  //         };
  //       }
  //       console.log("entry ",entry);

  //       return entry;
  //     });
  //   });
  // };

  const checkAllPendingTimeEntriesCheckbox = useMemo(() => {
    const pendingEntries = timeEntriesData.filter(
      (entry) => entry.time_entries_status === "pending"
    );
    return (
      pendingEntries.length > 0 &&
      pendingEntries.every((entry) => selectedEntries.has(entry.entries_id))
    );
  }, [timeEntriesData, selectedEntries]);

  // Modified page change handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
    setIsUpdated((prev) => !prev);
  };
  // Get all selected entries for bulk actions
  const getSelectedEntriesData = () => {
    return Array.from(selectedEntries.values());
  };
  const [filters, setFilters] = useState({
    status: null,
    entryAs: null,
  });
  useEffect(() => {
    const filterByStatus =
      fetchedTimeEntriesListTeam &&
      fetchedTimeEntriesListTeam?.filter((entry) =>
        filters.status
          ? entry.time_entries_status === filters.status.value
          : entry
      );
    const filterByEntryAs = filterByStatus?.filter((entry) =>
      filters.entryAs ? entry.entries_as === filters.entryAs.value : entry
    );

    sortByDesc(filterByEntryAs);
  }, [filters, fetchedTimeEntriesListTeam]);

  // checking if all entries are approved or not
  const allEntriesApproved = useMemo(
    () =>
      timeEntriesData.every(
        (entry) => entry.time_entries_status === "approved"
      ),
    [timeEntriesData]
  );

  const tableColumns = [
    {
      Header: () => (
        <input
          type="checkbox"
          checked={checkAllPendingTimeEntriesCheckbox}
          name="allTimeEntries"
          onChange={handleCheckbox}
          className="cursor-pointer checkbox-input"
        />
      ),
      id: "selection",
      Cell: ({ row }) =>
        row.original.time_entries_status === "pending" && (
          <input
            type="checkbox"
            onChange={handleCheckbox}
            name={row.original.entries_id}
            checked={selectedEntries.has(row.original.entries_id)}
            className="cursor-pointer checkbox-input"
          />
        ),
    },
    {
      Header: "Sr no.",
      accessor: "entries_id",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Member",
      accessor: "member_name",
    },

    {
      Header: "Job",
      accessor: "task_name",
      Cell: ({ row }) => row.original.task_name || "Side Work",
    },
    {
      Header: "Team",
      accessor: "team_name",
      Cell: ({ row }) => row.original.team_name ?? "---",
    },
    {
      Header: "Reviewer",
      accessor: "reviewer_name",
      Cell: ({ row }) => row.original.reviewer_name ?? "---",
    },
    {
      Header: "Status",
      accessor: "time_entries_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start">
          <Stack direction="horizontal">
            {row.original.time_entries_status === "approved" ? (
              <Badge bg="success">Approved</Badge>
            ) : (
              <Badge bg="danger">Pending</Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Working Date",
      accessor: "working_date",
      Cell: ({ row }) => {
        const date = formatDate(row.original.working_date);
        const time = formatTime(row.original.working_time);
        return (
          <div className="d-flex flex-column justify-content-start gap-1">
            <p className="m-0">{date}</p>
            <p className="m-0">{time}</p>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "work_description",
    },
  ];

  // Conditionally add Edit column based on userRole
  // if (userRole !== "members,team_sub_leader") {
  //   tableColumns.push({
  //     Header: "Edit",
  //     Cell: ({ row }) => (
  //       <>
  //         {row.original.time_entries_status === "pending" && (
  //           <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
  //             <Tooltip
  //               id="edit-time-entry-tooltip"
  //               style={{
  //                 background: "#000",
  //                 color: "#fff",
  //               }}
  //               opacity={0.9}
  //             />
  //             <div
  //               data-tooltip-id="edit-time-entry-tooltip"
  //               data-tooltip-content="Update Time Entry"
  //               data-tooltip-place="top"
  //             >
  //               <UpdateTimeEntryModal
  //                 setIsUpdated={setIsUpdated}
  //                 timeEntryData={row.original}
  //               />
  //             </div>
  //           </div>
  //         )}
  //       </>
  //     ),
  //   });
  // }

  const columns = useMemo(() => {
    if (!allEntriesApproved) {
      return tableColumns;
    } else {
      return tableColumns.filter((col) => col.id !== "selection");
    }
  }, [timeEntriesData, allEntriesApproved]);

  const data = useMemo(() => timeEntriesData, [timeEntriesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const sortByDesc = (data) => {
    if (data.length > 0) {
      const sortedData = data.sort((a, b) => {
        if (
          a.time_entries_status === "pending" &&
          b.time_entries_status !== "pending"
        ) {
          return -1;
        }
        if (
          a.time_entries_status !== "pending" &&
          b.time_entries_status === "pending"
        ) {
          return 1;
        }
        return new Date(b.working_date) - new Date(a.working_date);
      });

      setTimeEntriesData(sortedData);
    } else {
      setTimeEntriesData([]);
    }
  };
  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* {/ {/ Top header section /} /} */}
      <PageHeader
        tableInstance={tableInstance}
        icon={fileIcon}
        headerTitle={"Time Entries"}
      />
      <TimeEntriesFilter
        filters={filters}
        setFilters={setFilters}
        forTeamLeader={true}
      />

      {/* {/ {/ Approving Multiple Time Entries /} /} */}
      {selectedEntries.size > 0 && (
        <div className="mr-40 ml-30 mt-2">
          <SelectedMultipleEntriesModal
            timeEntriesData={getSelectedEntriesData()}
            setIsUpdated={setIsUpdated}
            onComplete={() => setSelectedEntries(new Map())}
            setSelectedEntriesStatus={setSelectedEntries}
            // handleLocalUpdate={handleLocalUpdate}
          />
        </div>
      )}

      {/* {/ {/ Time Entries Table /} /} */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : timeEntriesData?.length > 0 ? (
        <>
          <TimeEntriesTable
            tableInstance={tableInstance}
            headers={headers}
            timeEntriesData={timeEntriesData}
            columnHeaders={columnHeaders}
          />

          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  fetchedTimeEntriesListTeam?.length < pageSize ||
                  totalPages === 1
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-5 mr-40 ml-30 mb-15">
          <h5>No Entries found by your team!</h5>
        </div>
      )}
    </div>
  );
};

export default MyTeamTimeEntriesContent;
