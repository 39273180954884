import React, { createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  handleAPIError,
  headerOptions,
} from "../../utils/utilities/utilityFunctions";
import dayjs from "dayjs";
export const ContextAPI = createContext();
// export const nnAPIKey = `fcbf2fee98cee0be1da58b8a2066b7d3826f335def2261d693208e16275dde5b`;
export const nnAPIKey = process.env.REACT_APP_NN_API;

export const ApiContext = ({ children }) => {
  const navigate = useNavigate();

  // states
  const [initialState, setInitialState] = useState({
    jobCategories: [],
    billingServicesList: [],
    clientsList: [],
    adHocClientsList: [],
    membersList: [],
    teamsList: [],
    myTeams: {},
    advanceBillingTeamJobs: [],
    jobs: [],
    allTimeEntries: [],
    allBillableTimeEntries: [],
    myAllTimeEntries: [],
    myteamTimeEntries: [],
    assignJobsList: [],
    myJobs: [],
    postDraftChangesJobs: [],
    holidayList: [],
    allLogs: [],
    myLogs: [],
    getAllInvoice: [],
    allReports: [],
    myAllTimeEntriesBasedOnDate: [],
    isLoading: false,
    totalPages: 0,
    userRoleCount: [],
    advanceBillingJobList: [],
    advanceBillingReportList: [],
    singleTeam: {},
    singleClient: {},
    moreJobs: {},
    myTeamDetails: {},
    myTeamID: "",
    myTeamHasMore: "",
    moreAdvacedBillingJobs: {},
    unassignedMembersList: {},
    advanceBillingHasMore: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");

  // main api url
  const mainURL = "https://www.doshipms.com/api/"; // for live server & vercel both?
  // const mainURL = "https://doshipms-replica.neelnetworks.in/";
  // let  mainURL;
  //   if (process.env.NODE_ENV === 'development') {
  //     mainURL = "https://www.doshipms.com/api/";
  // } else {
  //     mainURL = "https://doshipms-replica.neelnetworks.in/";
  // }

  // logout function
  const logout = () => {
    if (localStorage.getItem("token")) {
      alert("Your token is Expired, Please login again!");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");
      localStorage.removeItem("username");
      navigate("/");
    }
  };

  // --------------------- FOR Single Member ---------------------------------------------------
  // getting logged in user details API
  const getUserDetails = async () => {
    try {
      const url = `${mainURL}single/member/${userId}`;
      const result = await axios.get(url, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        setUserDetails(result?.data?.member_details[0]);
      }
    } catch (e) {
      handleAPIError(e, logout);
    }
  };

  // -----------common structure for GET method api calling-----------------
  const getData = async (url, successCallback) => {
    try {
      setInitialState((prev) => ({ ...prev, isLoading: true }));
      const result = await axios.get(url, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        successCallback(result);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setInitialState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  // --------------------- FOR JOB CATEGORY ---------------------------------------------------
  // getting all job categories API
  const getAllJobCategories = () => {
    const url = `${mainURL}get/all-job-type/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        jobCategories: result?.data?.job_category_list ?? [],
      }));
    });
  };

  // const getAllAdvanceBillingJobs = () => {
  //   const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
  //     }));
  //   });
  // };

  const getAllAdvanceBillingJobs = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
          totalPages: result?.data?.assign_list?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
        }));
      });
    }
  };
  // --------------------- FOR BILLING SERVICES ---------------------------------------------------
  // getting all billing services API
  const getAllBillingServices = () => {
    const url = `${mainURL}services/billing/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        billingServicesList: result?.data?.services_list ?? [],
      }));
    });
  };
  // getting all invoice services API
  const getAllInvoice = () => {
    const url = `${mainURL}all/invoice/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        getAllInvoice: result?.data?.invoice_list ?? [],
      }));
    });
  };

  // --------------------- FOR ALL JOBS ---------------------------------------------------
  // getting all jobs API (For Admin and Manager User Roles)
  // const getAllJobs = () => {
  //   const url = `${mainURL}get/all-job/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       jobs: result?.data?.job_list ?? [],
  //     }));
  //   });
  // };
  // getting all jobs API (For Admin and Manager User Roles)
  const getAllJobs = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}get/all-job/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          jobs: result?.data?.job_list?.jobs ?? [],
          totalPages: result?.data?.job_list?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}get/all-job/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          jobs: result?.data?.job_list ?? [],
        }));
      });
    }
  };
  const getMoreAdvancedBillingJobs = (teamId, pageNo) => {
    if (teamId && pageNo) {
      const url = `${mainURL}/get-more/advance-billing-jobs/${userId}/${teamId.toString()}${
        pageNo ? `?page=${pageNo.toString()}` : ""
      }`;

      getData(url, (result) => {
        console.log("advacedBillingJob", result);
        setInitialState((prev) => ({
          ...prev,
          moreAdvacedBillingJobs: result?.data?.job_data ?? {},
        }));
      });
    }
  };
  // GetMoreJobs
  const getMoreJobs = (teamId, pageNo) => {
    if (teamId && pageNo) {
      // const url = `${mainURL}get-more/jobs/${userId}/`;
      const url = `${mainURL}get-more/jobs/${userId}/${teamId.toString()}${
        pageNo ? `?page=${pageNo.toString()}` : ""
      }`;
      getData(url, (result) => {

        setInitialState((prev) => ({
          ...prev,
          moreJobs: result?.data?.job_data ?? {},
        }));
      });
    }
  };

  // getting all jobs API (For Member and Team Leader User Roles)
  const getJobsDetailsByMemberId = () => {
    const url = `${mainURL}my-jobs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myJobs: result?.data?.job_data?.my_single_jobs ?? [],
        advanceBillingList: result?.data?.job_data?.advance_billing_jobs ?? [],
      }));
    });
  };

  // --------------------- FOR POST DRAFT CHANGES ---------------------------------------------------
  // getting all jobs whose invoice is created API
  const getJobsForPostDraftChanges = () => {
    const url = `${mainURL}generated_invoices/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        postDraftChangesJobs: result?.data?.invoices ?? [],
      }));
    });
  };

  // --------------------- FOR ALL ASSIGN JOBS ---------------------------------------------------
  // getting all assign jobs API (For Admin and Manager User Roles)

  // const getAllAssignJobs = () => {
  //   const url = `${mainURL}assign-jobs/${userId}`;
  //   getData(url, (result) => {
  //     const assignJobs = result?.data?.assign_list ?? [];
  //     const sortedJobs = assignJobs.sort((a, b) => {
  //       if (a.job_status === "In Progress" && b.job_status !== "In Progress")
  //         return -1;
  //       if (a.job_status !== "In Progress" && b.job_status === "In Progress")
  //         return 1;
  //       return 0;
  //     });

  //     setInitialState((prev) => ({
  //       ...prev,
  //       assignJobsList: sortedJobs,
  //     }));
  //   });
  // };
  // const getAllAssignJobs = () => {
  //   const url = `${mainURL}assign-jobs/${userId}`;
  //   getData(url, (result) => {
  //     const assignJobs = result?.data?.assign_list?.assign_list ?? [];
  //     const sortedJobs = assignJobs.sort((a, b) => {
  //       if (a.job_status === "In Progress" && b.job_status !== "In Progress")
  //         return -1;
  //       if (a.job_status !== "In Progress" && b.job_status === "In Progress")
  //         return 1;
  //       return 0;
  //     });

  //     setInitialState((prev) => ({
  //       ...prev,
  //       assignJobsList: sortedJobs,
  //     }));
  //   });
  // };
  const getAllAssignJobs = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}assign-jobs/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        const assignJobs = result?.data?.assign_list?.assign_list ?? [];
        // const assignJobs = result?.data?.assign_list ?? [];
        const sortedJobs = assignJobs.sort((a, b) => {
          if (a.job_status === "In Progress" && b.job_status !== "In Progress")
            return -1;
          if (a.job_status !== "In Progress" && b.job_status === "In Progress")
            return 1;
          return 0;
        });
        setInitialState((prev) => ({
          ...prev,
          assignJobsList: sortedJobs,
          totalPages: result?.data?.assign_list?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}assign-jobs/${userId}`;
      getData(url, (result) => {
        const assignJobs = result?.data?.assign_list?.assign_list ?? [];
        const sortedJobs = assignJobs.sort((a, b) => {
          if (a.job_status === "In Progress" && b.job_status !== "In Progress")
            return -1;
          if (a.job_status !== "In Progress" && b.job_status === "In Progress")
            return 1;
          return 0;
        });

        setInitialState((prev) => ({
          ...prev,
          assignJobsList: sortedJobs,
        }));
      });
    }
  };
  // --------------------- FOR CLIENTS ---------------------------------------------------------
  // fetch all clients API
  // const getAllClients = (pageNo, limit, searchQuery) => {
  //   if ((pageNo && limit) || (pageNo && limit && searchQuery)) {
  //     const url = `${mainURL}list/all-client${userId}?limit=${limit}&page=${pageNo}${
  //       searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : ""
  //     }`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         clientsList: result?.data?.client_list?.client_data ?? [],
  //       }));
  //     });
  //   } else {
  //     const url = `${mainURL}/list/all-client/${userId}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         clientsList: result?.data?.client_list?.client_data ?? [],
  //       }));
  //     });
  //   }
  // };

  const getAllClients = (page, limit, searchQuery) => {
    if ((page && limit) || (page && limit && searchQuery)) {
      let url = `${mainURL}list/all-client/${userId}?limit=${limit}&page=${page}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`; // Append search query
      }

      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          clientsList: result?.data?.client_list?.client_data ?? [],
          totalPages: result?.data?.client_list?.totalPages ?? 1,
        }));
      });
    } else {
      const url = `${mainURL}/list/all-client/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          clientsList: result?.data?.client_list?.client_data ?? [],
        }));
      });
    }
  };

  const getSingleClients = (clientId) => {
    const url = `${mainURL}/single/client/${userId}/${clientId}`;
    getData(url, (result) => {
      const SingleClientData = result?.data?.client_list?.client_data
        ? result?.data?.client_list?.client_data[0]
        : {};
      setInitialState((prev) => ({
        ...prev,
        singleClient: SingleClientData,
      }));
    });
  };

  const getAllAdHocClients = () => {
    const url = `${mainURL}get/invoice/ad-hoc-billing/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        adHocClientsList: result?.data?.ad_hoc_billing_lists ?? [],
      }));
    });
  };

  // --------------------- FOR MEMBERS ---------------------------------------------------------
  // getting all members API
  // const getAllMembers = () => {
  //   const url = `${mainURL}list/all-member/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       membersList: result?.data?.members_list?.users ?? [],
  //     }));
  //   });
  // };
  const getAllMembers = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}list/all-member/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          membersList: result?.data?.members_list?.users ?? [],
          totalPages: result?.data?.members_list?.totalPages,
          userRoleCount: result?.data?.members_list?.user_role_count,
        }));
      });
    } else {
      const url = `${mainURL}list/all-member/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          membersList: result?.data?.members_list?.users ?? [],
        }));
      });
    }
  };
  // --------------------- FOR TEAMS ---------------------------------------------------------
  // getting all teams API (For Admin and Manager User Roles)
  // const getAllTeams = () => {
  //   const url = `${mainURL}get/team/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       teamsList: result?.data?.team_list ?? [],
  //     }));
  //   });
  // };


  const getAllTeams = (page, limit, searchQuery) => {
  
    if ((page && limit) || (page && limit && searchQuery)) {
    let url = `${mainURL}get/team/${userId}?limit=${limit}&page=${page}`;
    if (searchQuery) {
      url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
    }
  
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        teamsList: result?.data?.team_list?.team_data ?? [],
        totalPages: result?.data?.team_list?.totalPages ?? 1,
      }));
    });

  
    } else {
      const url = `${mainURL}get/team/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          teamsList: result?.data?.team_list ?? [],
        }));
      });
    }
  };
  // getting all teams API (For Admin and Manager User Roles)
  const getSingleTeamDetails = (teamId) => {
    const url = `${mainURL}single/team/${userId}/${teamId}`;
    getData(url, (result) => {
      console.log("nbv", result);
      const teamData = result?.data?.team_list
        ? result?.data?.team_list[0]
        : {};
      setInitialState((prev) => ({
        ...prev,
        singleTeam: teamData,
      }));
    });
  };

  // getting all teams API (For Member and Team Leader User Roles)
  // const getTeamDetailsByMemberId = () => {
  //   const url = `${mainURL}my-teams/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       myTeams: result?.data?.team_data ? result?.data?.team_data[0] : {},
  //       advanceBillingList: result?.data?.team_data?.advance_billing_list ?? [],
  //     }));
  //   });
  // };

  // const getTeamDetailsByMemberId = () => {
  //   const url = `${mainURL}my-teams/${userId}`;

  //   getData(url, (result) => {
  //     console.log("result", result?.data?.team_data[0]);

  //     const teamData = result?.data?.team_data
  //       ? result?.data?.team_data[0].assigned_jobs.data
  //       : {};
  //     console.log("result abc", teamData);

  //     setInitialState((prev) => ({
  //       ...prev,
  //       myTeams: teamData,
  //       advanceBillingTeamJobs: teamData.advance_billing_list || [],
  //     }));
  //   });
  // };

  const getTeamDetailsByMemberId = (page, limit) => {
    // ==============================
    if (page && limit) {
      const url = `${mainURL}my-teams/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        const teamData = result?.data?.team_data
          ? result?.data?.team_data[0]
          : {};
        setInitialState((prev) => ({
          ...prev,
          myTeamID: teamData?.id,
          myTeamDetails: teamData,
          myTeams: teamData?.assigned_jobs?.data,
          myTeamHasMore: teamData?.assigned_jobs?.has_more,
          advanceBillingTeamJobs: teamData?.advance_billing_list?.data || [],
          advanceBillingHasMore: teamData?.advance_billing_list?.has_more,
        }));
      });
    } else {
      const url = `${mainURL}my-teams/${userId}`;
      getData(url, (result) => {
     

        const teamData = result?.data?.team_data
          ? result?.data?.team_data[0]
          : {};
        setInitialState((prev) => ({
          ...prev,
          myTeams: teamData?.assigned_jobs?.data,
          myTeamID: teamData?.id,
          myTeamDetails: teamData,
          myTeamHasMore: teamData?.assigned_jobs?.has_more,
          advanceBillingTeamJobs: teamData?.advance_billing_list?.data || [],
          advanceBillingHasMore: teamData.advance_billing_list?.has_more,
        }));
      });
    }
  };

  const getTeamDetailsByMemberI = () => {
    const url = `${mainURL}my-teams/${userId}`;
    getData(url, (result) => {
      const teamData = result?.data?.team_data
        ? result?.data?.team_data[0]
        : {};

      setInitialState((prev) => ({
        ...prev,
        myTeams: {
          data: teamData?.assigned_jobs?.data || [],
          has_more: teamData?.assigned_jobs?.has_more || false,
          teamId: teamData.id,
          total: teamData?.assigned_jobs?.total || 0,
        },
        advanceBillingTeamJobs: {
          data: teamData?.advance_billing_list?.data || [],
          has_more: teamData?.advance_billing_list?.has_more || false,
          total: teamData?.advance_billing_list?.total || 0,
        },
      }));
    });
  };
  const fetchPaginatedDataMemberMyTeam = (page, limit, teamId, callback) => {
    const url = `${mainURL}get-more/jobs/${userId}/${teamId}?page=${page}&limit=${limit}`;

    getData(url, (result) => {
      if (result?.data?.job_data) {
        const newTeamData = result.data.job_data;

        setInitialState((prev) => ({
          ...prev,
          myTeams: {
            ...prev.myTeams,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };

  const fetchPaginatedDataMemberAdvanceBilling = (
    page,
    limit,
    teamId,
    callback
  ) => {
    const url = `${mainURL}/get-more/advance-billing-jobs/${userId}/${teamId}?page=${page}&limit=${limit}`;

    getData(url, (result) => {
      if (result?.data?.job_data) {
        const newTeamData = result.data.job_data;

        setInitialState((prev) => ({
          ...prev,
          advanceBillingTeamJobs: {
            ...prev.advanceBillingTeamJobs,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };

  // --------------------- FOR ALL HOLIDAYS ---------------------------------------------------------
  // getting all holidays
  const getAllHolidays = () => {
    const url = `${mainURL}all/holiday`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        holidayList: result?.data?.holiday_list ?? [],
      }));
    });
  };

  // --------------------- FOR ALL TIME ENTRIES ---------------------------------------------------------
  // getting all time entries API for (Admin and Manager)
  // const getAllTimeEntries = () => {
  //   const url = `${mainURL}get/all-time-entries/${userId}`;
  //   getData(url, (result) => {
  //     const entries = result?.data?.time_entries_data?.time_entries ?? [];

  //     setInitialState((prev) => ({
  //       ...prev,
  //       allTimeEntries: entries,
  //     }));
  //   });
  // };
  const getAllTimeEntries = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}get/all-time-entries/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        const entries = result?.data?.time_entries_data?.time_entries ?? [];
        console.log("rsult", entries);
        setInitialState((prev) => ({
          ...prev,
          allTimeEntries: entries,
          totalPages: result?.data?.time_entries_data?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}get/all-time-entries/${userId}`;
      getData(url, (result) => {
        const entries = result?.data?.time_entries_data?.time_entries ?? [];

        setInitialState((prev) => ({
          ...prev,
          allTimeEntries: entries,
        }));
      });
    }
  };
//   const getAllBillableTimeEntries = (page, limit,searchQuery) => {
//     if ((page && limit) || (page && limit && searchQuery)) {
//      let url = `${mainURL}get/all-billable-hours-time-entries/${userId}?page=${page}&limit=${limit}`;
//      if (searchQuery) {
//        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
//      }
//      getData(url, (result) => {
//        setInitialState((prev) => ({
//          ...prev,
//          allBillableTimeEntries:
//            result?.data?.time_entries_data?.time_entries ?? [],
//          totalPages: result?.data?.time_entries_data?.totalPages,
//        }));
//      });
//    } else {
//      const url = `${mainURL}get/all-billable-hours-time-entries/${userId}`;
//      getData(url, (result) => {
//        setInitialState((prev) => ({
//          ...prev,
//          allBillableTimeEntries:
//            result?.data?.time_entries_data?.time_entries ?? [],
//        }));
//      });
//    }
//  };

const getAllBillableTimeEntries = (page, limit, searchQuery, filters) => {
  let url = `${mainURL}get/all-billable-hours-time-entries/${userId}?page=${page}&limit=${limit}`;

  // Include search query if provided
  if (searchQuery) {
    url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  }

  // Add filters to the URL
  if (filters) {
    if (filters.status && filters.status.value) {
      url += `&time_entries_status=${encodeURIComponent(filters.status.value)}`;
    }

    if (filters.entryAs && filters.entryAs.value) {
      url += `&entries_as=${encodeURIComponent(filters.entryAs.value)}`;
    }

    if (filters.showDiscountedHoursEntries !== undefined) {
      url += `&adjusted_hour=${filters.showDiscountedHoursEntries ? 'true' : 'false'}`;
    }

    if (filters.reviewer && filters.reviewer.value) {
      url += `&reviewer=${encodeURIComponent(filters.reviewer.value)}`;
    }
  }

  // Fetch data with the constructed URL
  getData(url, (result) => {
    setInitialState((prev) => ({
      ...prev,
      allBillableTimeEntries: result?.data?.time_entries_data?.time_entries ?? [],
      totalPages: result?.data?.time_entries_data?.totalPages,
    }));
  });
};


  // getting all time entries API for (Member and Team Leaders)
  const getAllMyTimeEntries = () => {
    const url = `${mainURL}get/my-time-entries/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myAllTimeEntries: result?.data?.time_entries_data ?? [],
      }));
    });
  };

  // getting all time entries API for (Member and Team Leaders)
  const getAllMyTimeEntriesBasedOnDate = (date) => {
    const url = `${mainURL}get/my-time-entries/${userId}/${date}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myAllTimeEntriesBasedOnDate: result?.data?.time_entries_data ?? [],
      }));
    });
  };

  // getting all time entries API for (Team Leaders)
  const getAllMyTeamTimeEntries = (teamId, page, limit) => {
    if (teamId) {
      const url = `${mainURL}get/team-time-entries/${userId}/${teamId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        console.log("resultresultresultresult", result);
        setInitialState((prev) => ({
          ...prev,
          myteamTimeEntries:
            result?.data?.time_entries_data?.time_entries ?? [],
          totalPages: result?.data?.time_entries_data?.totalPages,
        }));
      });
    }
  };
  const getAllMemberWiseReportData = (
    formattedStartDate,
    formattedEndDate,
    page,
    limit
  ) => {
    const url = `${mainURL}/reports/member-wise/${userId}/${formattedStartDate}/${formattedEndDate}?page=${page}&limit=${limit}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allMemberReports: result?.data?.member_wise_reports.reports_data ?? [],
        totalPages: result?.data?.time_entries_data?.totalPages,
      }));
    });
  };

  // --------------------- FOR USER LOGS ---------------------------------------------------------
  // getting all user logs API for (Admin and Manager)
  const getAllLogs = () => {
    const url = `${mainURL}login-logs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allLogs: result?.data?.logs_list ?? [],
      }));
    });
  };

  // getting my logs API for (Member and Team Leaders)
  const getMyLogs = () => {
    const url = `${mainURL}my-login-logs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myLogs: result?.data?.logs_list ?? [],
      }));
    });
  };

  // ------------------ FOR REPORT SECTION --------------------------------
  // getting all clients Report Data (Admin and Manager)
  const getAllClientsReportData = () => {
    const url = `${mainURL}reports/client-wise/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allReports: result?.data?.client_wise_reports ?? [],
      }));
    });
  };
  const getAdvanceBillingReportData = () => {
    const url = `${mainURL}reports/advanced-billing/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        advanceBillingReportList:
          result?.data?.advance_billing_jobs_reports?.jobs ?? [],
      }));
    });
  };

    // getMembersFreeFromTeam
    const getMembersFreeFromTeam = (pageNo) => {
      if (pageNo) {
        // const url = `${mainURL}get-more/jobs/${userId}/`;
        const url = `${mainURL}get/member-without-team/${userId}${
          pageNo ? `?page=${pageNo.toString()}` : ""
        }`;
  
        getData(url, (result) => {
          console.log("memberFree", result);
          setInitialState((prev) => ({
            ...prev,
            unassignedMembersList: result?.data?.unassigned_member_list ?? {},
          }));
        });
      }
    };
  return (
    <ContextAPI.Provider
      value={{
        mainURL,
        nnAPIKey,
        logout,
        getUserDetails,
        getAllJobCategories,
        getAllBillingServices,
        getAllAdvanceBillingJobs,
        getAllMembers,
        getAllClients,
        getAllAdHocClients,
        getAdvanceBillingReportData,
        getAllTeams,
        getTeamDetailsByMemberId,
        initialState,
        userDetails,
        getAllJobs,
        getJobsForPostDraftChanges,
        getJobsDetailsByMemberId,
        getAllAssignJobs,
        getAllHolidays,
        getAllMyTimeEntries,
        getAllTimeEntries,
        getAllBillableTimeEntries,
        getAllMyTeamTimeEntries,
        getAllMemberWiseReportData,
        getAllLogs,
        getMyLogs,
        setUserId,
        getAllInvoice,
        getAllClientsReportData,
        getAllMyTimeEntriesBasedOnDate,
        getSingleTeamDetails,
        getSingleClients,
        getMoreJobs,
        fetchPaginatedDataMemberAdvanceBilling,
        fetchPaginatedDataMemberMyTeam,
        getTeamDetailsByMemberI,
        getMoreAdvancedBillingJobs,
        getMembersFreeFromTeam
      }}
    >
      {children}
    </ContextAPI.Provider>
  );
};
