import React, { useEffect, useState } from "react";

const MobileWarning = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width as needed
    };

    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize); // Check on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up
    };
  }, []);

  if (isMobile) {
    return (
      <div style={styles.overlay}>
        <div style={styles.modal}>
          <p>This page is not allowed on mobile devices.</p>
          <button
            className="custom-btn"
            onClick={() => (window.location.href = "about:blank")}
          >
            OK
          </button>
        </div>
      </div>
    );
  }

  return null; // Render nothing if not mobile
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0)", // Semi-transparent background
    color: "white",
    textAlign: "center",
    paddingTop: "20%",
    zIndex: 1000, // Ensure it appears on top of everything
  },
  modal: {
    backgroundColor: "black",
    padding: "20px",
    borderRadius: "8px",
  },
};

export default MobileWarning;
