import React, { useEffect } from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const AdHocTable = ({
  tableInstance,
  headers,
  adHocClientsList,
}) => {
  const { setPageSize } = tableInstance;

  useEffect(() => setPageSize(10), []);

  return (
    <section className="">
      <ReactTable tableInstance={tableInstance} />
      <ReactTableFooter
        headers={headers}
        data={adHocClientsList}
        tableInstance={tableInstance}
      />
    </section>
  );
};

export default AdHocTable;
