// import React, { useContext, useEffect } from "react";

// import { ContextAPI } from "../../Context/ApiContext/ApiContext";
// import AllTimeEntriesContent from "./AdminOrManager/TimeEntries/AllTimeEntriesContent";
// import MyTeamTimeEntriesContent from "./MemberOrTeamLeaderJobs/TimeEntries/MyTeamTimeEntriesContent";
// import { useState } from "react";

// const TimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const {
//     getAllTimeEntries,
//     getTeamDetailsByMemberId,
//     getAllJobCategories,
//     getAllMyTeamTimeEntries,
//     initialState,
//   } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // Retrieve page number from localStorage, default to 1 if not found
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(
//     storedPage ? Number(storedPage) : 1
//   );

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(
//     storedPageSize ? Number(storedPageSize) : 10
//   );

//   useEffect(() => {
//     const handleRefresh = (event) => {
//       setCurrentPage(1);
//       setPageSize(10);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 10);
//     };
//     // Reset pagination on component mount
//     setCurrentPage(1);
//     setPageSize(10);
//     localStorage.setItem("currentPage", 1);
//     localStorage.setItem("pageSize", 10);
//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       // Clear localStorage values when component unmounts
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.addEventListener("beforeunload", handleRefresh);
//     };
//   }, []);

//   // useEffect(() => {
//   //   if (
//   //     userDetails?.member_role === "it_member" ||
//   //     userDetails?.member_role === "operation_member"
//   //   ) {
//   //     const timer = setTimeout(() => {
//   //       getAllJobCategories();
//   //       getAllTimeEntries(currentPage, pageSize);
//   //     }, 100);

//   //     // Cleanup function to clear the timer
//   //     return () => clearTimeout(timer);
//   //   } else {
//   //     // const timer = setTimeout(() => {
//   //     //   getTeamDetailsByMemberId(currentPage, pageSize);
//   //     // }, 100);
//   //     // // Cleanup function to clear the timer
//   //     // return () => clearTimeout(timer);
//   //   }
//   // }, [isUpdated, currentPage, pageSize]);

//   // useEffect(() => {
//   //   const timer = setTimeout(() => {
//   //     getAllJobCategories();
//   //     getAllTimeEntries(currentPage, pageSize);
//   //   }, 100);

//   //   // Cleanup function to clear the timer
//   //   return () => clearTimeout(timer);
//   // }, [isUpdated, currentPage, pageSize]);

//   useEffect(() => {
//     if (
//       (userDetails?.member_role && userDetails?.member_role === "it_member") ||
//       (userDetails?.member_role &&
//         userDetails?.member_role === "operation_member")
//     ) {
//       const timer = setTimeout(() => {
//         getAllTimeEntries(currentPage, pageSize);
//         getAllJobCategories();
//       }, 100);

//       // Cleanup function to clear the timer
//       return () => clearTimeout(timer);
//     }
//   }, [isUpdated, currentPage, pageSize, userDetails?.member_role]);

//   // useEffect(() => {
//   //   if (
//   //     userDetails?.member_role !== "it_member" ||
//   //     userDetails?.member_role !== "operation_member"
//   //   ) {
//   //     if (initialState.myTeams && initialState.myTeams.id) {
//   //       // Check if myTeams is defined and has an id
//   //       // Call the function and handle potential errors
//   //       const fetchTimeEntries = async () => {
//   //         try {
//   //           await getAllMyTeamTimeEntries(initialState.myTeams.id);
//   //         } catch (error) {
//   //           console.error("Failed to fetch time entries:", error);
//   //         }
//   //       };
//   //       fetchTimeEntries();
//   //     }
//   //   }
//   // }, [initialState.myTeams?.id, isUpdated]);

//   // useEffect(() => {
//   //   const { id } = initialState.myTeams || {};
//   //   if (id) {
//   //     const fetchTimeEntries = async () => {
//   //       try {
//   //         await getAllMyTeamTimeEntries(id, currentPage, pageSize);
//   //         getAllJobCategories();
//   //       } catch (error) {
//   //         console.error("Failed to fetch time entries:", error);
//   //       }
//   //     };

//   //     fetchTimeEntries();
//   //   }
//   // }, [initialState.myTeams?.id, currentPage, pageSize, isUpdated]);

//   useEffect(() => {
//     if (
//       (userDetails?.member_role &&
//         userDetails?.member_role === "team_leaders,members") ||
//       (userDetails?.member_role &&
//         userDetails?.member_role === "members,team_sub_leader")
//     ) {
//       if (initialState?.myTeamID && initialState?.myTeamID) {
//         const fetchTimeEntries = async () => {
//           try {
//             await getAllMyTeamTimeEntries(
//               initialState?.myTeamID,
//               currentPage,
//               pageSize
//             );
//             getAllJobCategories();
//           } catch (error) {
//             console.error("Failed to fetch time entries:", error);
//           }
//         };

//         fetchTimeEntries();
//       }
//     }
//   }, [initialState?.myTeamID, currentPage, pageSize, isUpdated]);

//   return (
//     <>
//       {userDetails?.member_role === "it_member" ||
//       userDetails?.member_role === "operation_member" ? (
//         <AllTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesList={initialState?.allTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//         />
//       ) : (
//         <MyTeamTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//         />
//       )}
//     </>
//   );
// };

// export default TimeEntries;

// import React, { useContext, useEffect } from "react";

// import { ContextAPI } from "../../Context/ApiContext/ApiContext";
// import AllTimeEntriesContent from "./AdminOrManager/TimeEntries/AllTimeEntriesContent";
// import MyTeamTimeEntriesContent from "./MemberOrTeamLeaderJobs/TimeEntries/MyTeamTimeEntriesContent";
// import { useState } from "react";

// const TimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const {
//     getAllTimeEntries,
//     getTeamDetailsByMemberId,
//     getAllJobCategories,
//     getAllMyTeamTimeEntries,
//     initialState,
//   } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // Retrieve page number from localStorage, default to 1 if not found
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(
//     storedPage ? Number(storedPage) : 1
//   );

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(
//     storedPageSize ? Number(storedPageSize) : 10
//   );

//   useEffect(() => {
//     getTeamDetailsByMemberId();
//   }, []);

//   useEffect(() => {
//     const handleRefresh = (event) => {
//       setCurrentPage(1);
//       setPageSize(10);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 10);
//     };
//     // Reset pagination on component mount
//     setCurrentPage(1);
//     setPageSize(10);
//     localStorage.setItem("currentPage", 1);
//     localStorage.setItem("pageSize", 10);
//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       // Clear localStorage values when component unmounts
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.addEventListener("beforeunload", handleRefresh);
//     };
//   }, []);

//   useEffect(() => {
//     if (
//       userDetails?.member_role === "it_member" ||
//       userDetails?.member_role === "operation_member"
//     ) {
//       const timer = setTimeout(() => {
//         getAllJobCategories();
//         getAllTimeEntries(currentPage, pageSize);
//       }, 100);

//       // Cleanup function to clear the timer
//       return () => clearTimeout(timer);
//     }
//   }, [isUpdated, currentPage, pageSize]);



//   useEffect(() => {
//     if (
//       userDetails?.member_role === "team_leaders,members" ||
//       userDetails?.member_role === "members,team_sub_leader"
//     ) {
//       if (initialState?.myTeamID && initialState?.myTeamID) {
//         const fetchTimeEntries = async () => {
//           try {
//             await getAllMyTeamTimeEntries(
//               initialState?.myTeamID,
//               currentPage,
//               pageSize
//             );
//             getAllJobCategories();
//           } catch (error) {
//             console.error("Failed to fetch time entries:", error);
//           }
//         };

//         fetchTimeEntries();
//       }
//     }
//   }, [initialState.myTeams?.id, currentPage, pageSize, isUpdated]);

//   return (
//     <>
//       {userDetails?.member_role === "it_member" ||
//       userDetails?.member_role === "operation_member" ? (
//         <AllTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesList={initialState?.allTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//         />
//       ) : (
//         <MyTeamTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//         />
//       )}
//     </>
//   );
// };

// export default TimeEntries;


import React, { useContext, useEffect } from "react";

import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import AllTimeEntriesContent from "./AdminOrManager/TimeEntries/AllTimeEntriesContent";
import MyTeamTimeEntriesContent from "./MemberOrTeamLeaderJobs/TimeEntries/MyTeamTimeEntriesContent";
import { useState } from "react";

const TimeEntries = () => {
  const { userDetails } = useContext(ContextAPI);
  const {
    getAllTimeEntries,
    getTeamDetailsByMemberId,
    getAllJobCategories,
    getAllMyTeamTimeEntries,
    initialState,
  } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    getTeamDetailsByMemberId();
  }, []);

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    if (
      userDetails?.member_role === "it_member" ||
      userDetails?.member_role === "operation_member"
    ) {
      const timer = setTimeout(() => {
        getAllJobCategories();
        getAllTimeEntries(currentPage, pageSize);
      }, 100);

      // Cleanup function to clear the timer
      return () => clearTimeout(timer);
    }
  }, [isUpdated, currentPage, pageSize, userDetails?.member_role]);

  useEffect(() => {
    if (
      userDetails?.member_role === "team_leaders,members" ||
      userDetails?.member_role === "members,team_sub_leader"
    ) {
      if (initialState?.myTeamID && initialState?.myTeamID) {
        // const fetchTimeEntries = async () => {
        //   try {
        //     await getAllMyTeamTimeEntries(
        //       initialState?.myTeamID,
        //       currentPage,
        //       pageSize
        //     );
        //     getAllJobCategories();
        //   } catch (error) {
        //     console.error("Failed to fetch time entries:", error);
        //   }
        // };

        // fetchTimeEntries();

        const timer = setTimeout(() => {
          getAllJobCategories();
          getAllMyTeamTimeEntries(
            initialState?.myTeamID,
            currentPage,
            pageSize
          );
        }, 100);

        // Cleanup function to clear the timer
        return () => clearTimeout(timer);
      }
    }
  }, [
    initialState.myTeams?.id,
    initialState?.myTeamID,
    currentPage,
    pageSize,
    isUpdated,
    userDetails?.member_role,
  ]);

  return (
    <>
      {userDetails?.member_role === "it_member" ||
      userDetails?.member_role === "operation_member" ? (
        <AllTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={initialState?.isLoading}
          fetchedTimeEntriesList={initialState?.allTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        <MyTeamTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={initialState?.isLoading}
          fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};

export default TimeEntries;
