
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import {
  usersIcon,
  searchIcon,
} from "../../../utils/ImportingImages/ImportingImages";

import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import MembersTable from "./MembersTable";
import { EditMemberModal } from "./EditMemberModal";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { DeleteMemberModal } from "./DeleteMemberModal";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { isGreaterThan10 } from "../../../utils/utilities/utilityFunctions";
import PageSizePopover from "../../../Components/PageSizePopover/PageSizePopover";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { nnAPIKey } from "../../../Context/ApiContext/ApiContext";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
const MembersContent = ({
  membersData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const token = localStorage.getItem("token");
  const bearer = `Bearer ${token}`;
  const { getAllMembers, mainURL, userRoleCount, initialState } =
    useContext(ContextAPI);
  const [searchInput, setSearchInput] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchPageSize, setSearchPageSize] = useState(pageSize);
  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Members",
      pageURL: "/members",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      //  Cell: ({ row }) => row.index + 1,
      Cell: ({ row }) => row?.original?.sr_no,
    },
    {
      Header: "Member Code",
      accessor: "member_code",
      Cell: ({ row }) => row.original?.member_code || "N.A",
    },
    {
      Header: "Name",
      accessor: "member_name",
      Cell: ({ row }) => row.original?.member_name || "N.A",
    },
    {
      Header: "Email ID",
      accessor: "member_email",
    },
    {
      Header: "Postion",
      accessor: "member_role",
      Cell: ({ row }) => {
        return row.original.member_role === "it_member"
          ? "IT Member"
          : row.original.member_role === "operation_member"
          ? "Operation Member"
          : row.original.member_role === "team_leaders"
          ? "Team Leader"
          : row.original.member_role === "members"
          ? "Member"
          : row.original.member_role === "team_leaders,members"
          ? "Team Leader, Member"
          : row.original.member_role === "members,team_sub_leader"
          ? "Member, Sub Leader"
          : row.original.member_role === "team_sub_leader"
          ? "Sub Leader"
          : "";
      },
    },
    {
      Header: "Status",
      accessor: "current_status",
      Cell: ({ row }) => {
        return row.original.current_status === "active"
          ? "Active"
          : row.original.current_status === "inactive"
          ? "Suspended"
          : "";
      },
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-member-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-member-tooltip"
            data-tooltip-content="Edit Member Details"
            data-tooltip-place="top"
          >
            <EditMemberModal
              memberData={row.original}
              setIsUpdated={setIsUpdated}
             
            />
          </div>

          <Tooltip
            id="delete-member-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-member-tooltip"
            data-tooltip-content="Delete Member"
            data-tooltip-place="top"
          >
            <DeleteMemberModal
              memberData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no",
    "Member Code",
    "Name",
    "Email ID",
    "Postion",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => membersData, [membersData]);
  const data = useMemo(
    () => (filteredMembers.length > 0 ? filteredMembers : membersData),
    [filteredMembers, membersData]
  );

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Member Code", key: "member_code" },
      { label: "Member Name", key: "member_name" },
      { label: "Email ID", key: "member_email" },
      { label: "Position", key: "member_role" },
      { label: "Registered on", key: "registered_on" },
      { label: "Status", key: "current_status" },
    ],
    fileName: "Members List",
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      // initialState: { pageSize: pageSize },
      initialState: { pageSize: searchPageSize },
      // initialState: { pageIndex: currentPage - 1, pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);
  const membersCount = initialState.userRoleCount;

  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [totalSearchPages, setTotalSearchPages] = useState(1);
  const [totalSearchRecords, setTotalSearchRecords] = useState(0);
  const handlePageSizeChangeSearch = (event) => {
    const newSize = Number(event.target.value);
    setSearchPageSize(newSize); // Update search page size
    setCurrentSearchPage(1); // Reset to the first page
    handleSearch(1, newSize); // Call handleSearch with the new page size
  };
  const handleSearch = async (page = 1, limit = searchPageSize) => {
    const userId = localStorage.getItem("userId");
    const query = searchInput.trim();

    if (!query) {
      // If search input is empty, reset filtered members to show all members
      setFilteredMembers(membersData);
      setSearchPerformed(false);
      setTotalSearchPages(Math.ceil(membersData.length / pageSize)); // Update total pages
      return;
    }
    setSearchPerformed(true);
   

    const url = `${mainURL}search/members_data/${userId}?query=${encodeURIComponent(
      query
    )}&page=${page}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Nn-Api-Key": nnAPIKey,
      Authorization: bearer,
    };

    setLoading(true);
    try {
      const result = await axios.get(url, { headers });
      console.log("API Result:", result); // Added log

      if (result.status === 200) {
        const members = result.data?.serachData?.members_data || [];
        const totalRecords = result.data?.serachData?.totalRecords || 0;
        const totalPages = result.data?.serachData?.totalPages || 0; // Correctly retrieve total pages

        setFilteredMembers(members);
        setTotalSearchRecords(totalRecords);
        setTotalSearchPages(totalPages); // Set the correct total pages
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchInput.trim() === "") {
      setFilteredMembers(membersData); // Reset to show all members
      setSearchPerformed(false); // Reset search state
    }
  }, [searchInput, membersData]);

  useEffect(() => {
    if (searchPerformed) {
      tableInstance.setPageSize(searchPageSize); // Update the table instance with new page size
      handleSearch(currentSearchPage, searchPageSize);
    }
  }, [currentSearchPage, searchPerformed, searchPageSize, tableInstance]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.trim() === "") {
      // Reset to original members data if the search input is cleared
      setFilteredMembers(membersData);
      setSearchPerformed(false);
    }
  };
 


  return (
    <>
      <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
        <div className="mr-40 ml-30 mb-15">
          <Breadcrumbs crumbs={breadCrumbs} />
        </div>

        <section className="main-content_header add-border-bottom">
          <div className="d-flex justify-content-center align-items-center page-heading">
            <p className="m-0 fs-4">Members</p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <div className="relative-wrapper">
              <img className="search-icon" src={searchIcon} alt="search-icon" />
              <input
                className="input-field"
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
            <button
              onClick={() => handleSearch(currentSearchPage)}
              className="custom-btn d-flex justify-content-center align-items-center gap-2"
            >
              {loading ? <SpinningLoader /> : "  Search"}
            </button>
            <button
              onClick={() => navigate("/members/add-member")}
              className="custom-btn d-flex justify-content-center align-items-center gap-2"
            >
              Add Member <span className="fw-light fs-4">+</span>
            </button>
          </div>
        </section>

        <section className="mt-5 parent-count-wrapper">
          <div className="count-wrapper">
            <p className="count">{isGreaterThan10(membersCount?.it_member)}</p>
            <p className="role">IT members</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.operation_member)}
            </p>
            <p className="role">Operation members</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.team_leaders)}
            </p>
            <p className="role">Team Leaders</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.team_sub_leader)}
            </p>
            <p className="role">Team Sub-Leaders</p>
          </div>
          <div className="count-wrapper">
            <p className="count">{isGreaterThan10(membersCount?.members)}</p>
            <p className="role">Members</p>
          </div>
        </section>

        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : (
          <>
            {filteredMembers.length === 0 && searchPerformed ? (
              <div className="mt-4 mr-40 ml-30 mb-15">
                <h5>No results found for your search: "{searchInput}"</h5>
              </div>
            ) : (
              <>
                <MembersTable
                  tableInstance={tableInstance}
                  headers={headers}
                  membersData={
                    filteredMembers.length > 0 ? filteredMembers : membersData
                  }
                />

                {searchInput.trim() ? (
                  // Search pagination
                  <div
                    className="d-flex justfy-content-center align-items-center gap-3 smallText"
                    style={{
                      width: "45%",
                      justifyContent: "space-between",
                      position: "absolute",
                      marginLeft: "90px",
                      bottom: "48px",
                    }}
                  >
                    <div className="d-flex gap-1 align-items-center">
                      <button
                        className="cursor-pointer"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => {
                          const newPage = Math.max(currentSearchPage - 1, 1);
                          setCurrentSearchPage(newPage);
                          handleSearch(newPage);
                        }}
                        disabled={currentSearchPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                          />
                        </svg>
                      </button>

                      <input
                        className="gotoPage-input-field"
                        type="number"
                        value={currentSearchPage}
                        readOnly
                      />
                      <span className="px-2">/</span>
                      <span>{totalSearchPages}</span>
                      <button
                        className="cursor-pointer"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => {
                          const newPage = Math.min(
                            currentSearchPage + 1,
                            totalSearchPages
                          );
                          setCurrentSearchPage(newPage);
                          handleSearch(newPage);
                        }}
                        disabled={currentSearchPage === totalSearchPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <span className="px-1 smallText">Rows / page</span>
                      <select
                        value={searchPageSize}
                        onChange={handlePageSizeChangeSearch}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  // Member pagination
                  <div
                    className="d-flex justfy-content-center align-items-center gap-3 smallText"
                    style={{
                      width: "45%",
                      justifyContent: "space-between",
                      position: "absolute",
                      marginLeft: "90px",
                      bottom: "48px",
                    }}
                  >
                    <div className="d-flex gap-1 align-items-center">
                      <button
                        className="cursor-pointer"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                          />
                        </svg>
                      </button>
                      <input
                        className="gotoPage-input-field"
                        type="number"
                        defaultValue={currentPage}
                        readOnly
                      />
                      <span className="px-2">/</span>
                      <span>{totalPages}</span>
                      <button
                        style={{ border: "none", background: "transparent" }}
                        className="cursor-pointer"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          membersData?.length < pageSize || totalPages === 1
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="px-1 smallText">Rows /page</span>
                      <select value={pageSize} onChange={handlePageSizeChange}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MembersContent;
