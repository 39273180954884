import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {
  EditSVG,
  projectsIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import {
  headerOptions,
  handleAPIError,
} from "../../../../utils/utilities/utilityFunctions";


const MyVerticallyCenteredModal = (props) => {
  const { mainURL, logout, initialState } = useContext(ContextAPI);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [amount, setAmount] = useState(""); 
 
  const statusOptions = [
    { label: "Unpaid", value: "1" },
    { label: "Partially Paid", value: "2" },
    { label: "Paid", value: "3" },
  ];

  // updating team job api
  const updateAdvanceBillingStatus = async () => {
    const body = {
      current_user: localStorage.getItem("userId"),
      invoice_id: props.id,
      status_id: paymentStatus?.value,
      partial_paid_amount: paymentStatus?.value === "2" ? amount : undefined,
     
    };

    const url =`${mainURL}update/invoice-status/advance-billing`;

    setIsLoading(() => true);

    try {
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      props.onHide();
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (paymentStatus?.value) {
      updateAdvanceBillingStatus();
    } else {
      if (!paymentStatus?.value) {
        ReactHotToast("Please select status", "error");
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Update Advance Billing Status </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdate}
          className="d-flex flex-column justify-content-center align-items-center"
        >
         
       
          <div className="form-group mt-3 w-100">
            <label htmlFor="paymentStatus">Status:</label>
            <Select
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable={false}
              isSearchable={true}
              name="paymentStatus"
              value={paymentStatus}
              onChange={(option) => setPaymentStatus(option)}
              options={statusOptions}
            />
         
          </div>
          {paymentStatus?.value === "2" && (
            <div className="form-group mt-3 w-100">
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                className="form-control"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                required
              />
            </div>
          )}

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const EditAdvanceBillingReportsStatusModel = ({ id, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <span
        onClick={() => {
          setModalShow(true);
        }}
      >
        <EditSVG />
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
  
        setIsUpdated={setIsUpdated}
id={id}

      />
    </>
  );
};
