import React, { useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { SendMailAdvanceBilling } from "../../../AdvanceBilling/components/SendMailAdvanceBilling";

const AdvancBillingReportInvoice = ({ url }) => {
  const { getAllAdvanceBillingJobs, getAdvanceBillingReportData } =
    useContext(ContextAPI);
  const location = useLocation();
  const { invoice_url, row, assignId, clientEmail } = location.state || {};
  const { sidebarClose } = useContext(ContextSidebarToggler);

  useEffect(() => {
    getAdvanceBillingReportData();
  }, []);
  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Advance Billing Report",
      pageURL: "/reports/advance_billing_report",
    },
    {
      pageName: "Adv Billing Report Invoice",
    },
  ];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      <div class="d-flex justify-content-end" style={{ marginRight: "30px" }}>
        <SendMailAdvanceBilling
          invoiceUrl={invoice_url}
          assignId={assignId}
          row={row}
          clientEmail={clientEmail}
        />
      </div>

      <div
        className="invoice-job"
        style={{ padding: "20px", textAlign: "center" }}
      >
        {invoice_url ? (
          <>
            <iframe
              src={invoice_url}
              title="advance_billing_report_invoice"
              style={{
                width: "100%",
                height: "700px",
                border: "none",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            />
          </>
        ) : (
          <p>No invoice available to display.</p>
        )}
      </div>
    </div>
  );
};

export default AdvancBillingReportInvoice;
