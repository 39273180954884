import React from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const AdvanceBillingTable = ({
  tableInstance,
  headers,
  advancBillingJobsData,
  columnHeaders,
}) => {
  return (
    <section className="ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} />
      <ReactTableFooter
        headers={headers}
        data={advancBillingJobsData}
        tableInstance={tableInstance}
        columnHeaders={columnHeaders}
        text="advancedBillingJobs"
      />
    </section>
  );
};

export default AdvanceBillingTable;
