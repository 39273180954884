import React, { useContext } from "react";
import MemberOrTeamLeaderJobs from "../MemberOrTeamLeaderJobs";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";

const MyJobsSection = () => {
  const { userDetails } = useContext(ContextAPI);

  return (
    <>
      {(userDetails?.member_role === "members,team_sub_leader" ||
        userDetails?.member_role === "members" ||
        userDetails?.member_role === "team_leaders,members") && (
        <MemberOrTeamLeaderJobs />
      )}
    </>
  );
};

export default MyJobsSection;
