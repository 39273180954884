// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { Tooltip } from "react-tooltip";

// import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
// import {
//   addIcon,
//   clientsIcon1,
//   subtractIcon,
// } from "../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
// import Breadcrumbs from "../../../templates/Breadcrumbs";
// import Select from "react-select";
// import {
//   handleAPIError,
//   headerOptions,
// } from "../../../utils/utilities/utilityFunctions";

// const AddClientsContent = () => {
//   const {
//     mainURL,
//     logout,
//     getAllJobCategories,
//     initialState,
//     getAllBillingServices,
//   } = useContext(ContextAPI);
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const [billingServiceName, setBillingServiceName] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [options, setOptions] = useState({ billingServicesOptions: [] });

//   const [clientDetails, setClientDetails] = useState({
//     clientName: "",
//     companyName: "",
//     email: "",
//     phone: "",
//     businessAddress: "",
//     consultant: "",
//     comment: "",
//     bpoNumber: "",
//     billingRate: 0,
//     accountingHead: "",
//   });

//   useEffect(() => {
//     getAllJobCategories();
//     getAllBillingServices();
//   }, []);

//   useEffect(() => {
//     setOptions((prev) => ({
//       ...prev,
//       billingServicesOptions: initialState?.billingServicesList
//         ?.filter(({ service_status }) => service_status === "active")
//         .map(({ services_name, services_id }) => ({
//           label: services_name,
//           value: services_id,
//         })),
//     }));
//   }, [initialState.billingServicesList]);

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/clients",
//     },
//     {
//       pageName: "Clients",
//       pageURL: "/clients",
//     },
//     {
//       pageName: "Add Client",
//       pageURL: "/add-client",
//     },
//   ];

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setClientDetails((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleServiceChange = (option, index) => {
//     const updatedServices = clientDetails.billingServices.map((s, i) =>
//       i === index ? { ...s, service_id: option?.value || "" } : s
//     );
//     setClientDetails((prev) => ({
//       ...prev,
//       billingServices: updatedServices,
//     }));
//     setSelectedServices(
//       updatedServices.map((s) => s.service_id).filter((id) => id)
//     );
//   };

//   // const handleRateChange = (index, rate) => {
//   //   console.log("index", index);
//   //   console.log("rate", rate);
//   //   // setClientDetails((prev) => ({
//   //   //   ...prev,
//   //   //   billingServices: prev.billingServices.map((service, i) =>
//   //   //     i === index ? { ...service, service_rate: rate } : service
//   //   //   ),
//   //   // }));
//   // };
//   const handleRateChange = (rate) => {
//     // console.log("index", index);
//     console.log("rate", rate);
//     // setClientDetails((prev) => ({
//     //   ...prev,
//     //   billingServices: prev.billingServices.map((service, i) =>
//     //     i === index ? { ...service, service_rate: rate } : service
//     //   ),
//     // }));
//   };

//   const addBillingService = () => {
//     setClientDetails((prev) => ({
//       ...prev,
//       billingServices: [
//         ...(prev.billingServices || []),
//         { service_id: "", service_rate: "" },
//       ],
//     }));
//   };

//   const removeBillingService = (index) => {
//     const updatedServices = (clientDetails.billingServices || []).filter(
//       (_, i) => i !== index
//     );

//     setClientDetails((prev) => ({
//       ...prev,
//       billingServices: updatedServices,
//     }));
//   };

//   const addNewBillingService = async () => {
//     const body = {
//       billing_services: billingServiceName,
//       current_user: localStorage.getItem("userId") ?? null,
//     };

//     setIsLoading(() => true);

//     console.log("addNewBillingServiceBody", body);

//     try {
//       // const url = `${mainURL}services/billing`;
//       // const result = await axios.post(url, body, {
//       //   headers: headerOptions(),
//       // });
//       // if (result.status === 201) {
//       //   ReactHotToast(result?.data?.message, "success");
//       //   setBillingServiceName(() => "");
//       //   setIsUpdated((prev) => !prev);
//       // }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//     }
//   };

//   // Adding New Client API
//   const addNewClient = async () => {
//     const body = {
//       current_user: localStorage.getItem("userId"),
//       client_name: clientDetails.clientName,
//       company_name: clientDetails.companyName,
//       email: clientDetails.email,
//       contact_no: clientDetails.phone,
//       billing_address: clientDetails.businessAddress,
//       consultant: clientDetails.consultant,
//       comment: clientDetails.comment,
//       bpo_no: clientDetails.bpoNumber,
//       billing_rates: clientDetails.billingRate,
//       accounting_head: clientDetails.accountingHead,
//     };

//     setIsLoading(() => true);

//     try {
//       const url = `${mainURL}/add-new/client`;
//       const result = await axios.post(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 201) {
//         ReactHotToast(result?.data?.message, "success");
//         setClientDetails({
//           clientName: "",
//           companyName: "",
//           email: "",
//           phone: "",
//           businessAddress: "",
//           consultant: "",
//           comment: "",
//           bpoNumber: "",
//           billingRate: 0,
//           accountingHead: "",
//         });
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//     }
//   };

//   const handleNewClient = (e) => {
//     e.preventDefault();

//     const {
//       clientName,
//       companyName,
//       email,
//       phone,
//       businessAddress,
//       consultant,
//       bpoNumber,
//       billingRate,
//       accountingHead,
//     } = clientDetails;

//     const bool =
//       clientName &&
//       companyName &&
//       email &&
//       phone &&
//       businessAddress &&
//       // consultant &&
//       bpoNumber;
//     // billingRate
//     // accountingHead;

//     if (bool) {
//       addNewClient();
//     } else {
//       const conditions = {
//         // [!consultant]: "Please input client consultant!",
//         // [!billingRate]: "Please input billing rate!",
//         // [!accountingHead]: "Please input accounting head",
//         [!businessAddress]: "Please input client address!",
//         [!phone]: "Please input client contact!",
//         [!email]: "Please input client email!",
//         [!companyName]: "Please input company name!",
//         [!bpoNumber]: "Please input client's BPO number!",
//         [!clientName]: "Please input client name!",
//       };

//       const errorMessage = conditions[true];
//       if (errorMessage) {
//         ReactHotToast(errorMessage, "error");
//       }
//     }
//   };

//   const handleAddBillingService = (e) => {
//     e.preventDefault();
//     if (billingServiceName) {
//       addNewBillingService();
//     } else {
//       ReactHotToast("Please add billing service name!", "error");
//     }
//   };

//   const getOptionsForIndex = (index) => {
//     // const selectedServiceIds = clientDetails.billingServices
//     //   .filter((_, i) => i !== index)
//     //   .map((service) => service.service_id);
//     // return options.billingServicesOptions.filter(
//     //   (option) => !selectedServiceIds.includes(option.value)
//     // );
//     const billingServiceOptions = initialState;
//     console.log("initialState", initialState);
//   };

//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       <section className="main-content_header">
//         <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
//           <img src={clientsIcon1} alt="clients" />
//           <p className="m-0 fs-4">Add Clients</p>
//         </div>
//       </section>

//       {/* <section className="main-content_form-section d-flex justify-content-center align-items-start w-100"> */}
//       <section className="main-content_form-section gap-5 d-flex align-items-start w-75 m-auto">
//         {/* <div class="row">
//   <div class="col-sm-6 mb-3 mb-sm-0">
//     <div class="card">
//       <div class="card-body">
//         <h5 class="card-title">Special title treatment</h5>
//         <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
//         <a href="#" class="btn btn-primary">Go somewhere</a>
//       </div>
//     </div>
//   </div>
//   <div class="col-sm-6">
//     <div class="card">
//       <div class="card-body">
//         <h5 class="card-title">Special title treatment</h5>
//         <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
//         <a href="#" class="btn btn-primary">Go somewhere</a>
//       </div>
//     </div>
//   </div>

// </div> */}

//         <form onSubmit={handleNewClient} className="w-50">
//           <div className="form-group mt-5">
//             <label htmlFor="clientName">Client Name:</label>
//             <input
//               id="clientName"
//               name="clientName"
//               placeholder="Eg: Raj Shah"
//               type="text"
//               required
//               value={clientDetails.clientName}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="bpoNumber">Client BPO No:</label>
//             <input
//               id="bpoNumber"
//               name="bpoNumber"
//               placeholder="Eg: DO101..."
//               max={10}
//               type="text"
//               value={clientDetails?.bpoNumber}
//               onChange={(e) => handleChange(e)}
//               required
//             />
//           </div>

//           <div className="form-group mt-3">
//             <label htmlFor="companyName">Company Name:</label>
//             <input
//               id="companyName"
//               name="companyName"
//               placeholder="Eg: Raj Industries"
//               type="text"
//               required
//               value={clientDetails.companyName}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="email">Primary Email Id:</label>
//             <input
//               id="email"
//               name="email"
//               placeholder="Eg: rajshah@gmail.com"
//               type="email"
//               required
//               value={clientDetails.email}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>

//           <div className="form-group mt-3">
//             <label htmlFor="phone">Phone Number:</label>
//             <input
//               id="phone"
//               name="phone"
//               placeholder="Eg: 0000 0000"
//               type="number"
//               required
//               value={clientDetails.phone}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="billingRate">Billing Rate:</label>
//             <input
//               id="billingRate"
//               name="billingRate"
//               placeholder="Eg: £ 150"
//               type="number"
//               // min={1}
//               // required
//               value={clientDetails.billingRate}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>

//           <div className="form-group mt-3">
//             <label htmlFor="businessAddress">Business Address:</label>
//             <input
//               id="businessAddress"
//               name="businessAddress"
//               type="text"
//               required
//               placeholder="Eg. A-204, Bhoomi Utsav, M G Road, Kandivali West, Mumbai, Maharashtra 400067"
//               value={clientDetails.businessAddress}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="consultant">Consultant:</label>
//             <input
//               id="consultant"
//               name="consultant"
//               placeholder="Eg: XYZ"
//               type="text"
//               // required
//               value={clientDetails.consultant}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>

//           <div className="form-group mt-3">
//             <label htmlFor="accountingHead">Accounting Head:</label>
//             <input
//               id="accountingHead"
//               name="accountingHead"
//               placeholder="Eg: John Doe"
//               type="text"
//               // required
//               value={clientDetails.accountingHead}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="comment">Comment (optional):</label>
//             <input
//               id="comment"
//               name="comment"
//               placeholder="Eg: comments..."
//               type="text"
//               value={clientDetails.comment}
//               onChange={(e) => handleChange(e)}
//             />
//           </div>

//           <button type="submit" className="mt-3 custom-btn d-flex ">
//             {isLoading ? <SpinningLoader /> : "Save"}
//           </button>
//         </form>
//         <div className="w-50">
//           <section className="">
//             <form
//               onSubmit={handleAddBillingService}
//               className="d-flex justify-content-between align-items-end gap-3"
//             >
//               <div className="form-group mt-5 w-100">
//                 <label htmlFor="name">Service Name:</label>
//                 <input
//                   id="name"
//                   name="name"
//                   placeholder="Eg: ITR Filling"
//                   type="text"
//                   required
//                   value={billingServiceName}
//                   onChange={(e) => setBillingServiceName(() => e.target.value)}
//                 />
//               </div>
//               <button type="submit" className="service-btn">
//                 {isLoading ? <SpinningLoader /> : "Add Service"}
//               </button>
//             </form>
//           </section>
//           {console.log("clientDetails", clientDetails)}
//           {/* {clientDetails?.billingServices?.length === 0 ? (
//             <div className="d-flex gap-3 align-items-center">
//               <div className="form-group mt-3 w-100">
//                 <label>Billing Service:</label>
//                 <Select
//                   isClearable
//                   id="billingService-0"
//                   name="billingService-0"
//                   closeMenuOnSelect={true}
//                   options={getOptionsForIndex(0)}
//                   value={null}
//                   onChange={(option) => {
//                     const updatedServices = [
//                       { service_id: option?.value || "", service_rate: "" },
//                     ];
//                     setClientDetails((prev) => ({
//                       ...prev,
//                       billingServices: updatedServices,
//                     }));
//                   }}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>
//               <div className="form-group mt-3 w-100">
//                 <label htmlFor="billingRate-0">Billing Rate (per hour):</label>
//                 <input
//                   id="billingRate-0"
//                   name="billingRate-0"
//                   placeholder="Eg: £100"
//                   type="number"
//                   value=""
//                   onChange={(e) => handleRateChange(0, e.target.value)}
//                 />
//               </div>
//             </div>
//           ) : (
//             clientDetails.billingServices.map((service, index) => (
//               <div key={index} className="d-flex gap-3 align-items-center">
//                 <div className="form-group mt-3 w-100">
//                   <label>Billing Service:</label>
//                   <Select
//                     isClearable
//                     id={`billingService-${index}`}
//                     name={`billingService-${index}`}
//                     closeMenuOnSelect={true}
//                     options={getOptionsForIndex(index)}
//                     value={
//                       getOptionsForIndex(index).find(
//                         (opt) => opt.value === String(service.service_id)
//                       ) || null
//                     }
//                     onChange={(option) => handleServiceChange(option, index)}
//                     className="react-select-custom-styling__container"
//                     classNamePrefix="react-select-custom-styling"
//                   />
//                 </div>
//                 <div className="form-group mt-3 w-100">
//                   <label htmlFor={`billingRate-${index}`}>
//                     Billing Rate (per hour):
//                   </label>
//                   <input
//                     id={`billingRate-${index}`}
//                     name={`billingRate-${index}`}
//                     placeholder="Eg: £100"
//                     type="number"
//                     value={service.service_rate || ""}
//                     onChange={(e) => handleRateChange(index, e.target.value)}
//                   />
//                 </div>
//                 {clientDetails?.billingServices?.length > 1 && (
//                   <div style={{ marginTop: "32px" }}>
//                     <Tooltip
//                       id="remove-billing-service-tooltip"
//                       style={{
//                         background: "#000",
//                         color: "#fff",
//                       }}
//                       opacity={0.9}
//                     />
//                     <img
//                       src={subtractIcon}
//                       data-tooltip-id="remove-billing-service-tooltip"
//                       data-tooltip-content="Remove Billing Service"
//                       data-tooltip-place="top"
//                       alt="remove billing service"
//                       onClick={() => removeBillingService(index)}
//                       className="cursor-pointer"
//                     />
//                   </div>
//                 )}
//                 {clientDetails?.billingServices?.length - 1 === index &&
//                   clientDetails?.billingServices?.length < 10 && (
//                     <div style={{ marginTop: "32px" }}>
//                       <Tooltip
//                         id="add-billing-service-tooltip"
//                         style={{
//                           background: "#000",
//                           color: "#fff",
//                         }}
//                         opacity={0.9}
//                       />
//                       <img
//                         src={addIcon}
//                         data-tooltip-id="add-billing-service-tooltip"
//                         data-tooltip-content="Add Billing Service"
//                         data-tooltip-place="top"
//                         alt="add billing service"
//                         className="cursor-pointer"
//                         onClick={addBillingService}
//                       />
//                     </div>
//                   )}
//               </div>
//             ))
//           )} */}

//           <div className="d-flex gap-3 align-items-center">
//             <div className="form-group mt-3 w-100">
//               <label>Billing Service:</label>
//               {JSON.stringify(options?.billingServicesOptions)}
//               <Select
//                 isClearable={true}
//                 id="billingService-0"
//                 name="billingService-0"
//                 closeMenuOnSelect={true}
//                 // options={getOptionsForIndex(0)}
//                 options={options?.billingServicesOptions}
//                 value={
//                   options?.billingServicesOptions.find(
//                     (opt) =>
//                       opt.value ===
//                       clientDetails?.billingServices?.[0]?.service_id
//                   ) || null
//                 }
//                 onChange={(option) => {
//                   const updatedServices = [
//                     { service_id: option?.value || "", service_rate: "" },
//                   ];

//                   setClientDetails((prev) => ({
//                     ...prev,
//                     billingServices: updatedServices,
//                   }));
//                 }}
//                 className="react-select-custom-styling__container"
//                 classNamePrefix="react-select-custom-styling"
//               />
//             </div>
//             <div className="form-group mt-3 w-100">
//               <label htmlFor="billingRate-0">Billing Rate (per hour):</label>
//               <input
//                 id="billingRate-0"
//                 name="billingRate-0"
//                 placeholder="Eg: £100"
//                 type="number"
//                 value=""
//                 // onChange={(e) => handleRateChange(0, e.target.value)}
//                 onChange={(e) => handleRateChange(e.target.value)}
//               />
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default AddClientsContent;
// ================================================
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Tooltip } from "react-tooltip";

import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import {
  addIcon,
  clientsIcon1,
  subtractIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import Select from "react-select";

import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import { useNavigate } from "react-router-dom";

const AddClientsContent = () => {
  const {
    mainURL,
    logout,
    getAllJobCategories,
    initialState,
    getAllBillingServices,
  } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [billingServiceName, setBillingServiceName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [options, setOptions] = useState({ billingServicesOptions: [] });
  const navigate = useNavigate();

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    companyName: "",
    email: "",
    phone: "",
    businessAddress: "",
    consultant: "",
    comment: "",
    bpoNumber: "",
    billingRate: 0,
    accountingHead: "",
    billingServices: [], // Store billing services here
  });

  useEffect(() => {
    getAllJobCategories();
    getAllBillingServices();
  }, [isUpdated]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      billingServicesOptions: initialState?.billingServicesList
        ?.filter(({ service_status }) => service_status === "active")
        .map(({ services_name, services_id }) => ({
          label: services_name,
          value: services_id,
        })),
    }));
  }, [initialState.billingServicesList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleServiceChange = (option, index) => {
    const updatedServices = clientDetails.billingServices.map((service, i) =>
      i === index ? { ...service, service_id: option?.value || "" } : service
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
    setSelectedServices(
      updatedServices.map((s) => s.service_id).filter((id) => id)
    );
  };

  const handleRateChange = (index, rate) => {
    const updatedServices = clientDetails.billingServices.map((service, i) =>
      i === index ? { ...service, service_rate: rate } : service
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
  };

  const addBillingService = () => {
    setClientDetails((prev) => ({
      ...prev,
      billingServices: [
        ...(prev.billingServices || []),
        { service_id: "", service_rate: "" },
      ],
    }));
  };

  const removeBillingService = (index) => {
    const updatedServices = (clientDetails.billingServices || []).filter(
      (_, i) => i !== index
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
  };

  const addNewBillingService = async () => {
    const body = {
      billing_services: billingServiceName,
      current_user: localStorage.getItem("userId") ?? null,
    };

    setIsLoading(() => true);
    // console.log("bodyNew Service", body);

    try {
      const url = `${mainURL}/services/billing`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setBillingServiceName(() => "");
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const addNewClient = async () => {
    const body = {
      current_user: localStorage.getItem("userId"),
      client_name: clientDetails.clientName,
      company_name: clientDetails.companyName,
      email: clientDetails.email,
      contact_no: clientDetails.phone,
      billing_address: clientDetails.businessAddress,
      consultant: clientDetails.consultant,
      comment: clientDetails.comment,
      bpo_no: clientDetails.bpoNumber,
      billing_rates: clientDetails.billingRate,
      accounting_head: clientDetails.accountingHead,
      billing_services: clientDetails.billingServices,
    };
    // console.log("bodyAddClient", body);
    setIsLoading(() => true);

    try {
      const url = `${mainURL}/add-new/client`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setClientDetails({
          clientName: "",
          companyName: "",
          email: "",
          phone: "",
          businessAddress: "",
          consultant: "",
          comment: "",
          bpoNumber: "",
          billingRate: 0,
          accountingHead: "",
          billingServices: [],
        });
        navigate("/clients");
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleNewClient = (e) => {
    e.preventDefault();
    const {
      clientName,
      companyName,
      email,
      phone,
      businessAddress,
      bpoNumber,
    } = clientDetails;

    const bool =
      clientName &&
      companyName &&
      email &&
      phone &&
      businessAddress &&
      bpoNumber;

    if (bool) {
      addNewClient();
    } else {
      const conditions = {
        [!businessAddress]: "Please input client address!",
        [!phone]: "Please input client contact!",
        [!email]: "Please input client email!",
        [!companyName]: "Please input company name!",
        [!bpoNumber]: "Please input client's BPO number!",
        [!clientName]: "Please input client name!",
      };

      const errorMessage = conditions[true];
      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };

  const handleAddBillingService = (e) => {
    e.preventDefault();
    if (billingServiceName) {
      addNewBillingService();
    } else {
      ReactHotToast("Please add billing service name!", "error");
    }
  };

  const getOptionsForIndex = (index) => {
    const selectedServiceIds = clientDetails.billingServices
      .filter((_, i) => i !== index)
      .map((service) => service.service_id);
    return options.billingServicesOptions.filter(
      (option) => !selectedServiceIds.includes(option.value)
    );
  };

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/clients",
    },
    {
      pageName: "Clients",
      pageURL: "/clients",
    },
    {
      pageName: "Add Client",
      pageURL: "/add-client",
    },
  ];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={clientsIcon1} alt="clients" />
          <p className="m-0 fs-4">Add Clients</p>
        </div>
      </section>

      <section className="main-content_form-section gap-5 d-flex align-items-start w-75 m-auto">
        <form onSubmit={handleNewClient} className="w-50">
          <div className="form-group mt-5">
            <label htmlFor="clientName">Client Name:</label>
            <input
              id="clientName"
              name="clientName"
              placeholder="Eg: Raj Shah"
              type="text"
              required
              value={clientDetails.clientName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="companyName">Company Name:</label>
            <input
              id="companyName"
              name="companyName"
              placeholder="Eg: Raj Industries"
              type="text"
              required
              value={clientDetails.companyName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="bpoNumber">Client BPO No:</label>
            <input
              id="bpoNumber"
              name="bpoNumber"
              placeholder="Eg: DO101..."
              max={10}
              type="text"
              value={clientDetails?.bpoNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="email">Primary Email Id:</label>
            <input
              id="email"
              name="email"
              placeholder="Eg: rajshah@gmail.com"
              type="email"
              required
              value={clientDetails.email}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="phone">Phone Number:</label>
            <input
              id="phone"
              name="phone"
              placeholder="Eg: 0000 0000"
              type="number"
              required
              value={clientDetails.phone}
              onChange={handleChange}
            />
          </div>

          {/* <div className="form-group mt-3">
            <label htmlFor="billingRate">Billing Rate:</label>
            <input
              id="billingRate"
              name="billingRate"
              placeholder="Eg: £ 150"
              type="number"
              value={clientDetails.billingRate}
              onChange={handleChange}
            />
          </div> */}

          <div className="form-group mt-3">
            <label htmlFor="businessAddress">Business Address:</label>
            <input
              id="businessAddress"
              name="businessAddress"
              type="text"
              required
              placeholder="Eg. A-204, Bhoomi Utsav, M G Road, Kandivali West, Mumbai, Maharashtra 400067"
              value={clientDetails.businessAddress}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="consultant">Consultant:</label>
            <input
              id="consultant"
              name="consultant"
              type="text"
              placeholder="Eg: Michael Smith"
              value={clientDetails.consultant}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="accountingHead">Accounting Head:</label>
            <input
              id="accountingHead"
              name="accountingHead"
              type="text"
              placeholder="Eg: John Doe"
              value={clientDetails.accountingHead}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="comment">Comment (optional):</label>
            <input
              id="comment"
              name="comment"
              type="text"
              placeholder="Eg: Important client"
              value={clientDetails.comment}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="mt-3 custom-btn d-flex ">
            {isLoading ? <SpinningLoader /> : "Save"}
          </button>
        </form>

        {/* Billing Service and Rate Section */}
        <div className="w-50">
          <section className="">
            <form
              onSubmit={handleAddBillingService}
              className="d-flex justify-content-between align-items-end gap-3"
            >
              <div className="form-group mt-5 w-100">
                <label htmlFor="name">Service Name:</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Eg: ITR Filling"
                  type="text"
                  required
                  value={billingServiceName}
                  onChange={(e) => setBillingServiceName(e.target.value)}
                />
              </div>
              <button type="submit" className="service-btn">
                {isLoading ? <SpinningLoader /> : "Add Service"}
              </button>
            </form>
          </section>
          {clientDetails.billingServices.length === 0 ? (
            <div className="d-flex gap-3 align-items-center">
              <div className="form-group mt-3 w-100">
                <label>Billing Service:</label>
                <Select
                  isClearable
                  id="billingService-0"
                  name="billingService-0"
                  closeMenuOnSelect={true}
                  options={getOptionsForIndex(0)}
                  value={null}
                  onChange={(option) => {
                    const updatedServices = [
                      { service_id: option?.value || "", service_rate: "" },
                    ];
                    setClientDetails((prev) => ({
                      ...prev,
                      billingServices: updatedServices,
                    }));
                  }}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
              <div className="form-group mt-3 w-100">
                <label htmlFor="billingRate-0">Billing Rate (per hour):</label>
                <input
                  id="billingRate-0"
                  name="billingRate-0"
                  placeholder="Eg: £100"
                  type="number"
                  value=""
                  onChange={(e) => handleRateChange(0, e.target.value)}
                />
              </div>
            </div>
          ) : (
            clientDetails.billingServices.map((service, index) => (
              <div key={index} className="d-flex gap-3 align-items-center">
                <div className="form-group mt-3 w-100">
                  <label>Billing Service:</label>
                  <Select
                    isClearable
                    id={`billingService-${index}`}
                    name={`billingService-${index}`}
                    closeMenuOnSelect={true}
                    options={getOptionsForIndex(index)}
                    value={
                      getOptionsForIndex(index).find(
                        (opt) => opt.value === String(service.service_id)
                      ) || null
                    }
                    onChange={(option) => handleServiceChange(option, index)}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                  />
                </div>
                <div className="form-group mt-3 w-100">
                  <label htmlFor={`billingRate-${index}`}>
                    Billing Rate (per hour):
                  </label>
                  <input
                    id={`billingRate-${index}`}
                    name={`billingRate-${index}`}
                    placeholder="Eg: £100"
                    type="number"
                    value={service.service_rate || ""}
                    onChange={(e) => handleRateChange(index, e.target.value)}
                  />
                </div>

                {/* Remove Icon */}
                {clientDetails.billingServices.length > 1 && (
                  <div style={{ marginTop: "32px" }}>
                    <Tooltip
                      id="remove-billing-service-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <img
                      src={subtractIcon}
                      data-tooltip-id="remove-billing-service-tooltip"
                      data-tooltip-content="Remove Billing Service"
                      data-tooltip-place="top"
                      alt="remove billing service"
                      onClick={() => removeBillingService(index)}
                      className="cursor-pointer"
                    />
                  </div>
                )}
                {/* Add New Billing Service Icon */}
                {clientDetails.billingServices.length - 1 === index &&
                  clientDetails.billingServices.length < 10 && (
                    <div style={{ marginTop: "32px" }}>
                      <Tooltip
                        id="add-billing-service-tooltip"
                        style={{
                          background: "#000",
                          color: "#fff",
                        }}
                        opacity={0.9}
                      />
                      <img
                        src={addIcon}
                        data-tooltip-id="add-billing-service-tooltip"
                        data-tooltip-content="Add Billing Service"
                        data-tooltip-place="top"
                        alt="add billing service"
                        className="cursor-pointer"
                        onClick={addBillingService}
                      />
                    </div>
                  )}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default AddClientsContent;
