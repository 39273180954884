import React, { useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdHocContent from "./AdHocContent";

const AdHoc = () => {
  const { getAllBillingServices, getAllAdHocClients,initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    // getAllBillingServices();
    getAllAdHocClients()
    
  }, [isUpdated]);

  return (
    <AdHocContent
    adHocClientsList={initialState.adHocClientsList}
      isLoading={initialState.isLoading}
      setIsUpdated={setIsUpdated}
    />
  );
};

export default AdHoc;
