// import React, { useState, useEffect, useContext } from "react";

// import AssignJobsContent from "./components/AssignJobsContent";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

// const AssignJobs = () => {
//   const { getAllAssignJobs, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllAssignJobs();
//   }, [isUpdated]);

//   return (
//     <AssignJobsContent
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState?.isLoading}
//     />
//   );
// };

// export default AssignJobs;
import React, { useState, useEffect, useContext } from "react";

import AssignJobsContent from "./components/AssignJobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const AssignJobs = () => {
  const { getAllAssignJobs, initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  // useEffect(() => {
  //   const handleRefresh = (event) => {
  //     setCurrentPage(1);
  //     localStorage.setItem("currentPage", 1);
  //     localStorage.setItem("pageSize", 50);
  //   };
  //   if (
  //     localStorage.getItem("currentPage") ||
  //     localStorage.getItem("pageSize")
  //   ) {
  //     localStorage.removeItem("currentPage");
  //     localStorage.removeItem("pageSize");
  //     setCurrentPage(1);
  //     localStorage.setItem("currentPage", 1);
  //     localStorage.setItem("pageSize", 50);
  //   } else {
  //     setCurrentPage(1);
  //     localStorage.setItem("currentPage", 1);
  //     localStorage.setItem("pageSize", 50);
  //   }

  //   window.addEventListener("beforeunload", handleRefresh);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleRefresh);
  //     // Clear localStorage values when component unmounts
  //     localStorage.removeItem("currentPage");
  //     localStorage.removeItem("pageSize");
  //   };
  // }, []);

  // useEffect(() => {
  //   // Reset pagination on component mount
  //   setCurrentPage(1);
  //   setPageSize(50);
  //   localStorage.setItem("currentPage", 1);
  //   localStorage.setItem("pageSize", 50);

  //   return () => {
  //     // Clear localStorage values when component unmounts
  //     localStorage.removeItem("currentPage");
  //     localStorage.removeItem("pageSize");
  //   };
  // }, []);

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  // useEffect(() => {
  //   getAllAssignJobs(currentPage, pageSize);
  // }, [isUpdated, currentPage, pageSize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllAssignJobs(currentPage, pageSize);
    }, 100);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [isUpdated, currentPage, pageSize]);

  return (
    // <AssignJobsContent
    //   setIsUpdated={setIsUpdated}
    //   isLoading={initialState?.isLoading}
    // />
    <AssignJobsContent
      setIsUpdated={setIsUpdated}
      isLoading={initialState?.isLoading}
      fetchedAssignJobList={initialState?.assignJobsList}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default AssignJobs;
