import React, { useState, useEffect, useContext } from "react";

import JobsContent from "./components/JobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const AdminOrManagerJobs = () => {
  const { getAllJobs, initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllJobs(currentPage, pageSize);
    }, 100);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [isUpdated, currentPage, pageSize]);

  return (
    <JobsContent
      jobsData={initialState?.jobs}
      setIsUpdated={setIsUpdated}
      isLoading={initialState.isLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default AdminOrManagerJobs;
