import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { fileIcon } from "../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import TimeEntriesTable from "../../Components/TimeEntriesTable";
import { UpdateTimeEntryModal } from "./components/UpdateTimeEntryModal";
import TimeEntriesFilter from "../../Components/TimeEntriesFilter";
import { AddTimeEntryModal } from "./components/AddTimeEntryModal";
import {
  formatDate,
  formatTime,
} from "../../../../utils/utilities/utilityFunctions";
import { CheckTimeEntryDetails } from "./components/CheckTimeEntryDetails";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Jobs",
    pageURL: "/jobs",
  },
  {
    pageName: "Time Entries",
    pageURL: "/jobs/time-entries",
  },
];

// constructing headers for CSV Link

const AllTimeEntriesContent = ({
  setIsUpdated,
  isLoading,
  fetchedTimeEntriesList,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  // const { getAllJobCategories, getAllTimeEntries, initialState } =
  //   useContext(ContextAPI);
  const [timeEntriesData, setTimeEntriesData] = useState([]);
  const [filters, setFilters] = useState({
    status: null,
    entryAs: null,
    showDiscountedHoursEntries: false,
    reviewer: "",
  });

  // useEffect(() => {
  //   getAllJobCategories();
  //   getAllTimeEntries();
  // }, [isUpdated]);

  const filterDiscountedData = (dataset) => {
    return dataset?.filter((entry) =>
      Boolean(entry?.adjustment_hours_reason?.trim())
    );
  };

  useEffect(() => {
    const filteredEntries =
      fetchedTimeEntriesList &&
      fetchedTimeEntriesList?.filter((entry) => {
        const matchesStatus = filters.status
          ? entry.time_entries_status === filters.status.value
          : true;
        const matchesEntryAs = filters.entryAs
          ? entry.entries_as === filters.entryAs.value
          : true;
        const matchesReviewer = filters.reviewer?.value
          ? entry.reviewer_name === filters.reviewer.value
          : true;
        const notAdvancedBilling = entry.time_entries_type !== "billable_hours";
        return (
          matchesStatus &&
          matchesEntryAs &&
          matchesReviewer &&
          notAdvancedBilling
        );
      });

    let entriesData = filters.showDiscountedHoursEntries
      ? filterDiscountedData(filteredEntries)
      : filteredEntries;

    entriesData = entriesData?.sort((a, b) => {
      if (
        a.time_entries_status === "pending" &&
        b.time_entries_status !== "pending"
      )
        return -1;
      if (
        a.time_entries_status !== "pending" &&
        b.time_entries_status === "pending"
      )
        return 1;
      return new Date(b.working_date) - new Date(a.working_date);
    });

    setTimeEntriesData(entriesData);
  }, [filters, fetchedTimeEntriesList]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "entries_id",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Member",
      accessor: "member_name",
    },
    { 
      Header: "Job", 
      accessor: "task_name", 
      Cell: ({ row }) => row.original.task_name || "---"
    },
    {
      Header: "Team",
      accessor: "team_name",
      Cell: ({ row }) => row.original.team_name ?? "Individual",
    },

    {
      Header: "Time Entries Type",
      accessor: "time_entries_type",
      Cell: ({ row }) => {
        const entryType = row.original.time_entries_type;

        switch (entryType) {
          case "advanced_billing":
            return "Advanced Billing";
          case "side_works":
            return "Side Work";
          default:
            return entryType;
        }
      },
    },

    {
      Header: "Reviewer",
      accessor: "reviewer_name",
      Cell: ({ row }) => row.original.reviewer_name ?? "---",
    },
    {
      Header: "Status",
      accessor: "time_entries_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start align-items-center">
          <Stack direction="horizontal">
            {row.original.time_entries_status === "approved" ? (
              <Badge bg="success">Approved</Badge>
            ) : (
              <Badge bg="danger">Pending</Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Working Date",
      accessor: "working_date",
      Cell: ({ row }) => {
        const date = formatDate(row.original.working_date);
        const time = formatTime(row.original.working_time);
        return (
          <div className="d-flex flex-column justify-content-start gap-1">
            <p className="m-0">{date}</p>
            <p className="m-0">{time}</p>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "work_description",
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <>
          {row.original.time_entries_status === "pending" && (
            <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
              <Tooltip
                id="edit-time-entry-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="edit-time-entry-tooltip"
                data-tooltip-content="Update Time Entry"
                data-tooltip-place="top"
              >
                <UpdateTimeEntryModal
                  setIsUpdated={setIsUpdated}
                  timeEntryData={row.original}
                />
              </div>
            </div>
          )}

          {row.original.time_entries_status === "approved" &&
          Boolean(row.original.adjustment_hours_reason?.trim()) ? (
            <>
              <Tooltip
                id="check-entry-details-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="check-entry-details-tooltip"
                data-tooltip-content="Check Entry Details"
                data-tooltip-place="top"
              >
                <CheckTimeEntryDetails timeEntry={row.original} />
              </div>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no.",
    "Member",
    "Job",
    "Team",
    "Reviewer",
    "Status",
    "Description",
    "Working Date",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => timeEntriesData, [timeEntriesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Member", key: "member_name" },
      { label: "Job", key: "task_name" },
      { label: "Team", key: "team_name" },
      { label: "Time Entries Type", key: "time_entries_type" },
      { label: "Reviewer", key: "reviewer_name" },
      { label: "Status", key: "time_entries_status" },
      { label: "Date", key: "working_date" },
      { label: "Time", key: "working_time" },
      { label: "Description", key: "work_description" },
    ],
    fileName: "Time Entries",
  };

  //Pagination Handling Functions
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  // Function to transform data for CSV export
  const transformDataForCSV = (TimeEntries) => {
    return TimeEntries.map((timeEntry) => {
      return {
        member_name: timeEntry?.member_name,
        task_name: timeEntry?.task_name ? timeEntry?.task_name : "--",
        team_name: timeEntry?.team_name ? timeEntry?.team_name : "--",
        time_entries_type:
          timeEntry?.time_entries_type === "advanced_billing"
            ? "Advanced Billing"
            : timeEntry?.time_entries_type === "side_works"
            ? "Side works"
            : "",
        reviewer_name: timeEntry?.reviewer_name,
        time_entries_status: timeEntry?.time_entries_status,
        working_date: timeEntry?.working_date,
        working_time: timeEntry?.working_time,
        work_description: timeEntry?.work_description,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <PageHeader
        tableInstance={tableInstance}
        icon={fileIcon}
        headerTitle={"Time Entries"}
      >
        <AddTimeEntryModal setIsUpdated={setIsUpdated} />
      </PageHeader>

      <TimeEntriesFilter
        filters={filters}
        setFilters={setFilters}
        forTeamLeader={false}
        timeEntries={fetchedTimeEntriesList}
        filterDiscountedData={filterDiscountedData}
      />

      {/* Time Entries Table */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : timeEntriesData?.length > 0 ? (
        <>
          <TimeEntriesTable
            tableInstance={tableInstance}
            headers={headers}
            timeEntriesData={csvInvoiceData}
            columnHeaders={columnHeaders}
          />
          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  fetchedTimeEntriesList?.length < pageSize || totalPages === 1
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="mr-40 ml-30 mt-4 mb-15">
          <h5>No Entries found!</h5>
        </div>
      )}
    </div>
  );
};

export default AllTimeEntriesContent;
