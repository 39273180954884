import React, { useContext, useEffect, useState } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";

import { teamsIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";

import {
  formatDate,
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";

const animatedComponents = makeAnimated();

const EditTeam = () => {
  const TeamID = useParams();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getAllMembers, getSingleTeamDetails, logout, mainURL, initialState } =
    useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Teams",
      pageURL: "/teams",
    },
    {
      pageName: "Manage Team",
      pageURL: "/teams/manage-team",
    },
  ];

  const [editedTeamData, setEditedTeamData] = useState({
    teamCode: "",
    teamName: "",
    teamMembersCount: 0,
    teamStatus: "",
    teamLeader: "",
    teamSubLeader: [],
    teamSubLeaderName: "",
    teamLeaderEmail: "",
    teamMembersName: [],
    jobs: [],
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const [membersOptions, setMembersOptions] = useState([]); // only responsible for maintaining track of members dropdown options
  const [teamLeaderOptions, setTeamLeaderOptions] = useState([]); // only responsible for maintaining track of team leader dropdown options
  const [teamSubLeaderOptions, setTeamSubLeaderOptions] = useState(); // only responsible for maintaining track of team leader dropdown options

  useEffect(() => {
    if (TeamID?.teamId) {
      getSingleTeamDetails(TeamID?.teamId);
    }
  }, [TeamID?.teamId]);

  // fetching all members list
  useEffect(() => {
    getAllMembers();
  }, []);

  console.log("editedTeamData", editedTeamData);
  console.log("initialState.membersList", initialState?.membersList);
  console.log("teamLeaderOptions", teamLeaderOptions);
  console.log("teamSubLeaderOptions", teamSubLeaderOptions);

  // Update editedTeamData when initialState.singleTeam has data
  useEffect(() => {
    if (initialState?.singleTeam) {
      setEditedTeamData({
        teamCode: initialState?.singleTeam?.team_code || "",
        teamName: initialState?.singleTeam?.team_name || "",
        teamMembersCount: initialState?.singleTeam?.member_count || 0,
        teamStatus: initialState?.singleTeam.status || "",
        teamLeaderEmail: initialState?.singleTeam.leader_email || "",
        jobs: initialState?.singleTeam?.assigned_jobs_list || [],
      });
    }
  }, [initialState.singleTeam]);

  // set project Option
  useEffect(() => {
    setProjectOptions(
      () =>
        editedTeamData.jobs &&
        editedTeamData.jobs.map((job) => ({
          label: job.task_name || "N/A",
          value: job.task_id,
        }))
    );
  }, [editedTeamData.jobs]);

  //   const generateOptions = (dataset, excludeMemberId) => {
  //     return dataset
  //       ?.filter((member) => {
  //         return (
  //           condition1(member) &&
  //           (condition2(member) || condition3(member)) &&
  //           member.current_status === "active" &&
  //           member.member_id !== excludeMemberId
  //         );
  //       })
  //       .map((member) => ({
  //         label: member.member_name,
  //         value: member.member_id,
  //       }));
  //   };

  // setting initial default members, in the members options dropdown
  // (not checking if the default member is selected team leader that we are checking in below useEffect)
  //   useEffect(() => {
  //     const defaultMembers = generateOptions(initialState?.membersList).filter(
  //       (member) => {
  //         const membersArray = initialState?.singleTeam?.team_member.split(",");
  //         for (let memberId of membersArray) {
  //           if (memberId === member.value) return true;
  //         }
  //       }
  //     );

  //     const defaultTeamLeader = generateOptions(initialState?.membersList)?.find(
  //       (member) => member.value === initialState?.singleTeam?.team_leader
  //     );

  //     const selectedSubTeamLeaders = initialState.membersList

  //       .filter(
  //         (member) =>
  //           initialState?.singleTeam?.sub_leaders &&
  //           initialState?.singleTeam.sub_leaders
  //             .split(",")
  //             .includes(member.member_id)
  //       )
  //       .map((member) => ({
  //         label: member.member_name,
  //         value: member.member_id,
  //       }));

  //     setEditedTeamData((prev) => ({
  //       ...prev,
  //       teamMembersName: defaultMembers,
  //       teamLeader: defaultTeamLeader,
  //       teamSubLeader: selectedSubTeamLeaders,
  //     }));
  //   }, [initialState?.membersList]);

  //   useEffect(() => {
  //     // Extract the team member IDs from the initialState
  //     const teamMemberIds =
  //       initialState?.singleTeam?.team_member &&
  //       initialState?.singleTeam?.team_member.split(",").map((id) => id.trim());

  //     // Filter the members from initialState.memberList who are in teamMemberIds
  //     const filteredMembers =
  //       initialState.memberList &&
  //       initialState.memberList.filter((member) =>
  //         teamMemberIds.includes(member.id)
  //       );

  //     // Map filtered members to the format the Select component expects
  //     const membersOptions =
  //       filteredMembers &&
  //       filteredMembers.map((member) => ({
  //         value: member.id,
  //         label: member.name,
  //       }));

  //     // Update the state with the filtered members
  //     setEditedTeamData((prev) => ({
  //       ...prev,
  //       teamMembersName: membersOptions, // Set the filtered member options here
  //     }));
  //   }, []);

  //   const generateOptions = (dataset, excludeMemberId) => {
  //     return dataset
  //       ?.filter((member) => {
  //         return (
  //           condition1(member) &&
  //           (condition2(member) || condition3(member)) &&
  //           member.current_status === "active" &&
  //           member.member_id !== excludeMemberId
  //         );
  //       })
  //       .map((member) => ({
  //         label: member.member_name,
  //         value: member.member_id,
  //       }));
  //   };

  //   useEffect(() => {
  //     const defaultMembers = generateOptions(initialState?.membersList).filter(
  //       (member) => {
  //         const membersArray = initialState?.singleTeam?.team_member.split(",");
  //         for (let memberId of membersArray) {
  //           if (memberId === member.value) return true;
  //         }
  //       }
  //     );

  //     const defaultTeamLeader = generateOptions(initialState?.membersList)?.find(
  //       (member) => member.value === initialState?.singleTeam?.team_leader
  //     );

  //     const selectedSubTeamLeaders = initialState?.membersList
  //       ?.filter(
  //         (member) =>
  //           initialState?.singleTeam?.sub_leaders &&
  //           initialState?.singleTeam?.sub_leaders
  //             .split(",")
  //             .includes(member.member_id)
  //       )
  //       .map((member) => ({
  //         label: member.member_name,
  //         value: member.member_id,
  //       }));

  //     setEditedTeamData((prev) => ({
  //       ...prev,
  //       teamMembersName: defaultMembers,
  //       teamLeader: defaultTeamLeader,
  //       teamSubLeader: selectedSubTeamLeaders,
  //     }));
  //   }, [initialState?.membersList]);

  // ---------------------------------------------

  // Effect for team leader

  //   const getMemberOptions = (excludeId) =>
  //     initialState.membersList
  //       ?.filter(
  //         (member) =>
  //           member.current_status === "active" && member.member_id !== excludeId
  //       )
  //       .map((member) => ({
  //         label: member?.member_name,
  //         value: member?.member_id,
  //       }));

  //   // Get Team Leader Options
  //   useEffect(() => {
  //     // Retrieve the team leader's ID from `initialState`
  //     const teamLeaderId =
  //       initialState?.singleTeam?.team_leader &&
  //       initialState?.singleTeam?.team_leader;

  //     console.log("teamLeaderId", teamLeaderId);

  //     // Pass `teamLeaderId` as the excludeId to `getMemberOptions`
  //     const teamLeaderOptions = getMemberOptions(teamLeaderId)?.filter(
  //       (member) => member.value === teamLeaderId
  //     );

  //     console.log("WWWWWW", teamLeaderOptions);

  //     // Update state only if there are options available
  //     setTeamLeaderOptions(teamLeaderOptions);
  //   }, [
  //     initialState?.singleTeam?.team_leader,
  //     initialState.memberList,
  //     editedTeamData.teamLeader,
  //   ]);

  //   // Get Team Sub-Leader Options
  //   useEffect(() => {
  //     // Split `subLeaderIds` into an array if it exists
  //     const subLeaderIds = initialState?.singleTeam?.sub_leaders
  //       ? initialState.singleTeam.sub_leaders.split(",")
  //       : [];

  //     // Filter members based on whether their value is in `subLeaderIds`
  //     const subLeaderOptions = getMemberOptions(subLeaderIds)?.filter(
  //       (member) => subLeaderIds.includes(String(member.value)) // Convert `member.value` to a string for comparison
  //     );

  //     setTeamSubLeaderOptions(subLeaderOptions);
  //   }, [
  //     initialState?.singleTeam?.sub_leaders,
  //     initialState.memberList,
  //     editedTeamData.teamSubLeader,
  //   ]);

  const updateTeam = async () => {
    //  setIsLoading(() => true);
    //  try {
    //    const body = {
    //      current_user: +localStorage.getItem("userId") ?? null,
    //      team_name: updatedTeamData.teamName,
    //      team_leader: +updatedTeamData.teamLeader?.value,
    //      team_sub_leader: updatedTeamData.teamSubLeader
    //        .map(({ value }) => value)
    //        .join(","),
    //      team_member: updatedTeamData.teamMembersName
    //        .map(({ value }) => value)
    //        .join(","),
    //      team_id: +initialState?.singleTeam.id,
    //      status: updatedTeamData.teamStatus,
    //    };
    //    const url = `${mainURL}update/team`;
    //    const result = await axios.put(url, body, {
    //      headers: headerOptions(),
    //    });
    //    if (result.status === 200) {
    //      ReactHotToast(result.data.message, "success");
    //      navigate("/teams");
    //    }
    //  } catch (e) {
    //    handleAPIError(e, logout);
    //  } finally {
    //    setIsLoading(() => false);
    //  }
  };

  const handleUpdateTeam = (e) => {
    e.preventDefault();
    //  if (
    //    updatedTeamData.teamName &&
    //    updatedTeamData.teamLeader &&
    //    updatedTeamData.teamLeaderEmail &&
    //    updatedTeamData.teamMembersName &&
    //    updatedTeamData.teamStatus
    //  ) {
    //    updateTeam();
    //  } else {
    //    if (updatedTeamData.teamName === "") {
    //      ReactHotToast("Please input team name!", "error");
    //    } else if (updatedTeamData.teamLeader === "") {
    //      ReactHotToast("Please select team leader!", "error");
    //      // } else if (updatedTeamData.teamLeaderEmail === "") {
    //      //   ReactHotToast("Please input team leader email!", "error");
    //    } else if (updatedTeamData.teamMembersName.length <= 0) {
    //      ReactHotToast("Please select team members!", "error");
    //    } else if (updatedTeamData.teamStatus === "") {
    //      ReactHotToast("Please select team status!", "error");
    //    }
    //  }
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={teamsIcon1} alt="Manage Team" />
          <p className="m-0 fs-4">Manage Team</p>
        </div>
      </section>
      <section className="main-content_form-section d-flex justify-content-center align-items-start w-75 m-auto">
        <form
          onSubmit={handleUpdateTeam}
          className="w-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div className="d-flex justify-content-center align-items-start gap-5 w-100">
            <div className="w-50">
              <div className="form-group mt-5">
                <label htmlFor="teamCode">Team Code:</label>
                <input
                  id="teamCode"
                  name="teamCode"
                  placeholder="Eg: 00101"
                  type="text"
                  value={editedTeamData?.teamCode}
                  required
                  disabled
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamName">Team Name:</label>
                <input
                  id="teamName"
                  name="teamName"
                  placeholder="Eg: Team Titans"
                  type="text"
                  //   value={initialState?.singleTeam?.team_name}
                  value={editedTeamData?.teamName}
                  onChange={(e) =>
                    setEditedTeamData((prev) => ({
                      ...prev,
                      teamName: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamLeader">Team Leader:</label>
                <Select
                  name="teamLeader"
                  closeMenuOnSelect={true}
                  options={teamLeaderOptions}
                  onChange={(value) => {
                    const updatedSubLeaders =
                      editedTeamData.teamSubLeader.filter(
                        (subLeader) => subLeader.value !== value.value
                      );

                    setEditedTeamData((prev) => ({
                      ...prev,
                      teamLeader: value,
                      teamSubLeader: updatedSubLeaders,
                    }));
                  }}
                  value={editedTeamData.teamLeader}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamSubLeader">Team Sub-Leader:</label>

                <Select
                  name="teamSubLeader"
                  closeMenuOnSelect={true}
                  isMulti
                  options={teamSubLeaderOptions}
                  //    options={generateMemberOptions()}
                  onChange={(value) => {
                    setEditedTeamData((prev) => ({
                      ...prev,
                      teamSubLeader: value,
                    }));
                  }}
                  value={editedTeamData.teamSubLeader}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
              {/* <div className="form-group mt-4">
                <label htmlFor="teamSubLeaderName">
                  Current Sub Leader Name:
                </label>
                <input
                  id="teamSubLeaderName"
                  name="teamSubLeaderName"
                  value={updatedTeamData?.teamSubLeaderName}
                  disabled
                />
              </div> */}

              <div className="form-group mt-4">
                <label htmlFor="teamLeaderEmail">Team Leader Email:</label>
                <input
                  id="teamLeaderEmail"
                  name="teamLeaderEmail"
                  placeholder="Eg: mailto:rajshah@gmail.com"
                  type="email"
                  value={editedTeamData?.teamLeaderEmail}
                  disabled
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamMemberCount">Team Member Count:</label>
                <input
                  id="teamMemberCount"
                  name="teamMemberCount"
                  placeholder="Eg: 12"
                  type="number"
                  value={editedTeamData?.teamMembersCount}
                  disabled
                  required
                />
              </div>
              <button
                type="submit"
                className="mt-4 w-25 custom-btn d-flex justify-content-center align-items-center"
              >
                {isLoading ? <SpinningLoader /> : "Update"}
              </button>
            </div>
            <div className="w-50">
              <div className="form-group mt-5">
                <label htmlFor="teamMembers">Team Members:</label>

                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={membersOptions}
                  onChange={(member) => {
                    setEditedTeamData((prev) => ({
                      ...prev,
                      teamMembersName: member,
                    }));
                  }}
                  name="teamMembers"
                  value={editedTeamData.teamMembersName}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamStatus">Team Status:</label>
                <Select
                  name="teamStatus"
                  closeMenuOnSelect={true}
                  // options={statusOptions}
                  onChange={({ value }) =>
                    setEditedTeamData((prev) => ({
                      ...prev,
                      teamStatus: value,
                    }))
                  }
                  value={
                    editedTeamData.teamStatus === "active"
                      ? { label: "Active", value: "active" }
                      : { label: "Inactive", value: "inactive" }
                  }
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="clientName">Assigned Jobs List:</label>
                <Select
                  name="teamStatus"
                  closeMenuOnSelect={true}
                  options={projectOptions}
                  onChange={(option) =>
                    setEditedTeamData((prev) => ({
                      ...prev,
                      selectedJob: option,
                    }))
                  }
                  value={editedTeamData.selectedJob}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
              {/* {editedTeamData.selectedJob && (
                <div className={`projectDetails mt-4`}>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <p className={`bigText fs-5 m-0`}>
                      Project Name:{" "}
                      <span className={`smallText fs-6`}>
                        {editedTeamData.selectedJob.label}
                      </span>
                    </p>
                    <Stack direction="horizontal">
                      {getJobDetails()?.job_status === "Completed" ? (
                        <Badge bg="success">Completed</Badge>
                      ) : getJobDetails()?.job_status === "On Hold" ? (
                        <Badge bg="danger">On Hold</Badge>
                      ) : (
                        <Badge bg="warning" text="dark">
                          In Progress
                        </Badge>
                      )}
                    </Stack>
                  </div>
                  <p className="fs-6 m-0 mt-3">
                    {getJobDetails()?.job_description}
                  </p>
                  <p className="fs-5 m-0 mt-3">Estimated Timeline: </p>
                  <p className="fs-6 m-0">
                    {formatDate(getJobDetails()?.assigned_on)} to{" "}
                    {formatDate(getJobDetails()?.due_on)}
                  </p>
                </div>
              )} */}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default EditTeam;
