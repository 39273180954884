import React, { useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdvanceBillingReportContent from "./components/AdvanceBillingReportContent";

const AdvanceBillingReport = () => {
  const { initialState, getAdvanceBillingReportData } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    getAdvanceBillingReportData();
  }, [isUpdated]);

  return (
    <AdvanceBillingReportContent
      advancebillingReportData={initialState?.advanceBillingReportList}
      setIsUpdated={setIsUpdated}
      isLoading={initialState?.isLoading}
    />
  );
};

export default AdvanceBillingReport;
