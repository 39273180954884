// import React, { useContext, useMemo, useEffect, useState } from "react";
// import {
//   DoubleArrowsSVG,
//   DownArrow,
//   UpArrow,
// } from "../utils/ImportingImages/ImportingImages";
// import AccordionComponent from "../Pages/Teams/MemberOrTeamLeader/components/AccordionComponents";
// const ReactTable = ({ tableInstance, currentRow, setIsUpdated }) => {
//   const userRole = localStorage.getItem("userRole");
//   const [selectedIds, setSelectedIds] = useState([]);
//   const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
//     tableInstance;

//   const noSorting = ["Edit", "Delete", "Action"];

//   return (
//     <table
//       {...getTableProps()}
//       className="table mt-4 text-center react-table"
//       style={{ width: "100%", borderCollapse: "collapse" }}
//     >
//       <thead className="react-table_thead" style={{ textAlign: "justify" }}>
//         {headerGroups.map((headerGroup) => (
//           <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
//             {headerGroup.headers.map((column) => (
//               <th
//                 {...column.getHeaderProps(column.getSortByToggleProps())}
//                 key={column.id}
//               >
//                 <span>
//                   <span>{column.render("Header")} </span>
//                   <span>
//                     {noSorting.includes(column.Header) ||
//                     column.id === "selection_id" ? null : column.isSorted ? (
//                       column.isSortedDesc ? (
//                         <span className="sorting_arrow-size">
//                           <DownArrow />
//                         </span>
//                       ) : (
//                         <span className="sorting_arrow-size">
//                           <UpArrow />
//                         </span>
//                       )
//                     ) : (
//                       <span className="sorting_arrow-size">
//                         <DoubleArrowsSVG />
//                       </span>
//                     )}
//                   </span>
//                 </span>
//               </th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody className="react-table_tbody" {...getTableBodyProps()}>
//         {page.length > 0 ? (
//           page.map((row) => {
//             prepareRow(row);
//             return (
//               <React.Fragment key={row.id}>
//                 {/* <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <td
//                       {...cell.getCellProps()}
//                       className="table-cell"
//                       style={{
//                         textAlign: "left",
//                         padding: "8px",
//                         whiteSpace: "nowrap",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis",
//                       }}
//                     >
//                       {cell.render("Cell")}
//                     </td>
//                   ))}
//                 </tr> */}
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => {
//                     const cellValue = cell.render("Cell");
//                     const isEmail =
//                       cell.column.id === "client_email" ||
//                       cell.column.id === "member_email" ||
//                       cell.column.id === "email";

//                     return (
//                       <td
//                         {...cell.getCellProps()}
//                         className={isEmail ? "email-cell" : "table-cell"}
//                         style={{
//                           textAlign: "left",
//                           // padding: "8px",
//                           // whiteSpace: "nowrap",
//                           // overflow: "hidden",
//                           // textOverflow: "ellipsis",
//                           // maxWidth: "100px"
//                           // maxWidth: "200px",
//                           // wordBreak: "break-all",
//                         }}
//                       >
//                         {isEmail && typeof cellValue === "string"
//                           ? cellValue.toLowerCase()
//                           : cellValue}
//                       </td>
//                     );
//                   })}
//                 </tr>

//                 {row.original.task_id && row.original.task_id === currentRow ? (
//                   <tr>
//                     {userRole === "team_leaders,members" ||
//                     userRole === "members,team_sub_leader" ? (
//                       <td colSpan={11}>
//                         <AccordionComponent
//                           setIsUpdated={setIsUpdated}
//                           content={row.original.time_entries_for_task}
//                           selectedIds={selectedIds}
//                           setSelectedIds={setSelectedIds}
//                         />
//                       </td>
//                     ) : null}
//                   </tr>
//                 ) : null}
//               </React.Fragment>
//             );
//           })
//         ) : (
//           <tr>
//             <td
//               style={{ textAlign: "center", padding: "8px" }}
//               colSpan={headerGroups[0].headers.length}
//             >
//               No data found!
//             </td>
//           </tr>
//         )}
//       </tbody>
//     </table>
//   );
// };

// export default ReactTable;
import React, { useContext, useMemo, useEffect, useState } from "react";
import {
  DoubleArrowsSVG,
  DownArrow,
  UpArrow,
} from "../utils/ImportingImages/ImportingImages";
import AccordionComponent from "../Pages/Teams/MemberOrTeamLeader/components/AccordionComponents";
const ReactTable = ({
  tableInstance,
  currentRow,
  setIsUpdated,
  onJobUpdateforTimeEntries,
}) => {
  const userRole = localStorage.getItem("userRole");
  const [selectedIds, setSelectedIds] = useState([]);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const noSorting = ["Edit", "Delete", "Action"];

  return (
    <table
      {...getTableProps()}
      className="table mt-4 text-center react-table"
      style={{ width: "100%", borderCollapse: "collapse" }}
    >
      <thead className="react-table_thead" style={{ textAlign: "justify" }}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={column.id}
              >
                <span>
                  <span>{column.render("Header")} </span>
                  <span>
                    {noSorting.includes(column.Header) ||
                    column.id === "selection_id" ? null : column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="sorting_arrow-size">
                          <DownArrow />
                        </span>
                      ) : (
                        <span className="sorting_arrow-size">
                          <UpArrow />
                        </span>
                      )
                    ) : (
                      <span className="sorting_arrow-size">
                        <DoubleArrowsSVG />
                      </span>
                    )}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="react-table_tbody" {...getTableBodyProps()}>
        {page.length > 0 ? (
          page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                {/* <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="table-cell"
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr> */}
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const cellValue = cell.render("Cell");
                    const isEmail =
                      cell.column.id === "client_email" ||
                      cell.column.id === "member_email" ||
                      cell.column.id === "email";

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={isEmail ? "email-cell" : "table-cell"}
                        style={{
                          textAlign: "left",
                          // padding: "8px",
                          // whiteSpace: "nowrap",
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // maxWidth: "100px"
                          // maxWidth: "200px",
                          // wordBreak: "break-all",
                        }}
                      >
                        {isEmail && typeof cellValue === "string"
                          ? cellValue.toLowerCase()
                          : cellValue}
                      </td>
                    );
                  })}
                </tr>

                {row.original.task_id && row.original.task_id === currentRow ? (
                  <tr>
                    {userRole === "team_leaders,members" ||
                    userRole === "members,team_sub_leader" ? (
                      <td colSpan={11}>
                        <AccordionComponent
                          setIsUpdated={setIsUpdated}
                          content={row.original.time_entries_for_task}
                          selectedIds={selectedIds}
                          setSelectedIds={setSelectedIds}
                          currentRow={currentRow}
                          onJobUpdateforTimeEntries={onJobUpdateforTimeEntries}
                        />
                      </td>
                    ) : null}
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td
              style={{ textAlign: "center", padding: "8px" }}
              colSpan={headerGroups[0].headers.length}
            >
              No data found!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ReactTable;
