// import React, { useContext, useEffect, useState } from "react";

// import TeamsContent from "./components/TeamsContent";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

// const MemberOrTeamLeaderTeams = () => {
//   const { getTeamDetailsByMemberId, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   const userRole = localStorage.getItem("userRole");

//   useEffect(() => {
//     getTeamDetailsByMemberId();
//   }, [isUpdated]);

//   return (
//     <>
//       {/* {userRole === "team_leaders,members" ? ( */}
//       <TeamsContent
//         teamData={initialState?.myTeams}
//         advanceBillingTeamJob={initialState?.advanceBillingTeamJobs}
//         setIsUpdated={setIsUpdated}
//       />
//       {/* ):null } */}
//     </>
//   );
// };

// export default MemberOrTeamLeaderTeams;

import React, { useContext, useEffect, useState } from "react";
import TeamsContent from "./components/TeamsContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

const MemberOrTeamLeaderTeams = () => {
  const {
    getTeamDetailsByMemberI,
    fetchPaginatedDataMemberMyTeam,
    fetchPaginatedDataMemberAdvanceBilling,
    initialState,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [limit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [teamId, setTeamId] = useState(localStorage.getItem("teamId") || "");

  // Pagination states
  const [myJobsPage, setMyJobsPage] = useState(1);
  const [advanceBillingPage, setAdvanceBillingPage] = useState(1);
  const [teamFlag, setTeamFlag] = useState(true);
  const [advanceBillingFlag, setAdvanceBillingFlag] = useState(true);
  const [activeTab, setActiveTab] = useState("myJobs");

  // Local state to store current data
  const [currentTeamData, setCurrentTeamData] = useState({
    data: [],
    has_more: false,
  });
  const [currentAdvanceBillingData, setCurrentAdvanceBillingData] = useState({
    data: [],
    has_more: false,
  });
  console.log("currentTeamdata", currentTeamData);

  console.log("currentAdvanceBillingData", currentAdvanceBillingData);

  // Fetch initial data for the selected tab (either team jobs or advance billing)
  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      await getTeamDetailsByMemberI((result) => {
        if (result?.data?.team_data) {
          const teamData = result?.data?.team_data[0] || {};

          // Update states based on active tab
          if (activeTab === "myJobs") {
            setCurrentTeamData({
              data: teamData?.assigned_jobs?.data || [],
              has_more: teamData?.assigned_jobs?.has_more || false,
            });
            setTeamFlag(teamData?.assigned_jobs?.has_more || false);
          } else {
            setCurrentAdvanceBillingData({
              data: teamData?.advance_billing_list?.data || [],
              has_more: teamData?.advance_billing_list?.has_more || false,
            });
            setAdvanceBillingFlag(
              teamData?.advance_billing_list?.has_more || false
            );
          }

          // Set teamId if available
          if (teamData.id) {
            setTeamId(teamData.id);
            localStorage.setItem("teamId", teamData.id);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching initial team details:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Update local state when initial data changes
  useEffect(() => {
    if (initialState?.myTeams) {
      setCurrentTeamData({
        data: initialState.myTeams.data || [],
        has_more: initialState.myTeams.has_more,
      });
      setTeamFlag(initialState.myTeams.has_more);
      const newTeamId = initialState.myTeams.teamId;
      if (newTeamId) {
        setTeamId(newTeamId);
        localStorage.setItem("teamId", newTeamId);
      }
    }

    if (initialState?.advanceBillingTeamJobs) {
      setCurrentAdvanceBillingData({
        data: initialState.advanceBillingTeamJobs.data || [],
        has_more: initialState.advanceBillingTeamJobs.has_more,
      });
      setAdvanceBillingFlag(initialState.advanceBillingTeamJobs.has_more);
    }
  }, [initialState]);

  // If both pages (myJobsPage and advanceBillingPage) are 1, fetch initial data
  useEffect(() => {
    if (myJobsPage === 1 && advanceBillingPage === 1) {
      fetchInitialData(); // Fetch the initial data for both tabs
    }
  }, [myJobsPage, advanceBillingPage]);

  // Handle Team Jobs pagination
  useEffect(() => {
    if (myJobsPage > 1 && activeTab === "myJobs") {
      setIsLoading(true);
      fetchPaginatedDataMemberMyTeam(myJobsPage, limit, teamId, (result) => {
        if (result?.data?.job_data) {
          setCurrentTeamData((prev) => ({
            data: [...prev.data, ...(result.data.job_data.data || [])],
            has_more: result.data.job_data.has_more,
          }));
        }
        setIsLoading(false);
      });
    }
  }, [myJobsPage, activeTab]);

  // Handle Advance Billing pagination
  useEffect(() => {
    if (advanceBillingPage > 1 && activeTab === "advanceBilling") {
      setIsLoading(true);
      fetchPaginatedDataMemberAdvanceBilling(
        advanceBillingPage,
        limit,
        teamId,
        (result) => {
          if (result?.data?.job_data) {
            setCurrentAdvanceBillingData((prev) => ({
              data: [...prev.data, ...(result.data.job_data.data || [])], // Append new data
              has_more: result.data.job_data.has_more,
            }));
          }
          setIsLoading(false);
        }
      );
    }
  }, [advanceBillingPage, activeTab]);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Reset pagination when switching tabs
    if (tab === "myJobs") {
      setMyJobsPage(1);
      // Set to initial team jobs data from context
      if (initialState?.myTeams) {
        setCurrentTeamData({
          data: initialState.myTeams.data || [],
          has_more: initialState.myTeams.has_more,
        });
      }
    } else {
      setAdvanceBillingPage(1);
      // Set to initial advance billing data from context
      if (initialState?.advanceBillingTeamJobs) {
        setCurrentAdvanceBillingData({
          data: initialState.advanceBillingTeamJobs.data || [],
          has_more: initialState.advanceBillingTeamJobs.has_more,
        });
      }
    }
  };
  // Update local state when a job is updated
  const handleLocalUpdate = (updatedJob) => {
    if (activeTab === "myJobs") {
      setCurrentTeamData((prev) => ({
        ...prev,
        data: prev.data.map((job) =>
          job.task_id === updatedJob.task_id ? updatedJob : job
        ),
      }));
    } else {
      setCurrentAdvanceBillingData((prev) => ({
        ...prev,
        data: prev.data.map((job) =>
          job.task_id === updatedJob.task_id ? updatedJob : job
        ),
      }));
    }
  };
  const handleLocalUpdateforTimeEntries = (updatedJob) => {
    const { time_entries_id, time_entries_status, task_id } = updatedJob;

    // Normalize time_entries_id to an array
    const updatedTimeEntryIds = Array.isArray(time_entries_id)
      ? time_entries_id
      : time_entries_id.split(",").map((id) => id.trim());

    // console.log("Updated Time Entry IDs:", updatedTimeEntryIds); // Log IDs

    // Function to update time entries status
    const updateTimeEntriesStatus = (prevJobs) => {
      return prevJobs.map((job) => {
        if (job.task_id === task_id) {
          console.log("Found matching job:", job); // Log matching job

          return {
            ...job,
            time_entries_for_task: job.time_entries_for_task.map(
              (timeEntry) => {
                console.log("Processing time entry:", timeEntry); // Log time entry being processed

                if (
                  updatedTimeEntryIds.includes(
                    timeEntry.time_entries_id.toString()
                  )
                ) {
                  console.log(
                    "Updating time entry status:",
                    timeEntry.time_entries_id
                  ); // Log matching time entry
                  return {
                    ...timeEntry,
                    time_entries_status: time_entries_status, // Update status
                  };
                }
                return timeEntry; // Return unchanged time entry
              }
            ),
          };
        }
        return job; // Return unchanged job if task_id doesn't match
      });
    };

    // Update state for the current tab
    if (activeTab === "myJobs") {
      console.log("Updating myJobs state..."); // Log active tab update
      setCurrentTeamData((prev) => ({
        ...prev,
        data: updateTimeEntriesStatus(prev.data),
      }));
    } else {
      console.log("Updating other tab..."); // Placeholder for other tabs
      // Uncomment to update other state if required
      // setCurrentAdvanceBillingData((prev) => ({
      //   ...prev,
      //   data: updateTimeEntriesStatus(prev.data),
      // }));
    }

    console.log("State update complete for activeTab:", activeTab); // Log completion
  };

  return (
    <TeamsContent
      teamData={currentTeamData}
      advanceBillingTeamJob={currentAdvanceBillingData}
      setIsUpdated={setIsUpdated}
      isLoading={isLoading}
      teamFlag={teamFlag}
      advanceBillingFlag={advanceBillingFlag}
      myJobsPage={myJobsPage}
      setMyJobsPage={setMyJobsPage}
      advanceBillingPage={advanceBillingPage}
      setAdvanceBillingPage={setAdvanceBillingPage}
      limit={limit}
      activeTab={activeTab}
      setActiveTab={handleTabChange}
      onJobUpdate={handleLocalUpdate}
      onJobUpdateforTimeEntries={handleLocalUpdateforTimeEntries}
    />
  );
};

export default MemberOrTeamLeaderTeams;
