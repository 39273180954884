import React, { useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import ClientsTable from "./ClientsTable";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { DeleteClientModal } from "./DeleteClientModal";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { EditSVG } from "../../../utils/ImportingImages/ImportingImages";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import {
  clientsIcon1,
  searchIcon,
} from "../../../utils/ImportingImages/ImportingImages";

import "../../../stylesheet/CommonCSS.css";
import { ClientAdditionalDataModel } from "./ClientAdditionalDataModal";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Clients",
    pageURL: "/clients",
  },
];
const columnHeaders = ["Sr no.", "Client Code", "Name", "Email ID", "Edit"];
// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Client Code", key: "client_code" },
    { label: "Client BPO No", key: "bpo_no" },
    { label: "Client Name", key: "client_name" },
    { label: "Company Name", key: "company_name" },
    { label: "Email ID", key: "client_email" },
  ],
  fileName: "Clients List",
};

const ClientsContent = ({
  clientsData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  searchInput,
  setSearchInput,
  pageSize,
  totalPages,
  setPageSize,
  handleSearch,handleInputChange
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };
  const [loading, setLoading] = useState(false);
  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };
  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Client BPO No",
      // accessor: "client_code",
      Cell: ({ row }) => row.original?.additional_data?.bpo_no,
    },
    {
      Header: "Name",
      accessor: "client_name",
    },
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: ({ row }) => row.original?.additional_data?.company_name,
    },
    {
      Header: "Email ID",
      accessor: "client_email",
      Cell: ({ row }) => row.original?.additional_data?.primary_email,
    },

    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Client Additional Data"
            data-tooltip-place="top"
            style={{ cursor: "pointer" }}
          >
            <ClientAdditionalDataModel
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>

          <Tooltip
            id="edit-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-client-tooltip"
            data-tooltip-content="Edit Client Details"
            data-tooltip-place="top"
            onClick={() => navigate(`/clients/${row.original.client_id}`)}
          >
            <EditSVG />
          </div>

          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Delete Client"
            data-tooltip-place="top"
          >
            <DeleteClientModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);

  const data = useMemo(() => clientsData, [clientsData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);
  // Function to transform data for CSV export

  const transformDataForCSV = (clientsData) => {
    return clientsData.map((client) => {
      return {
        client_code: client.client_code,
        bpo_no: client?.additional_data?.bpo_no
          ? client?.additional_data?.bpo_no
          : "--",
        client_name: client?.client_name,
        company_name: client?.additional_data?.company_name,
        client_email: client?.client_email,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
        <img src={clientsIcon1} alt="client" />
          <p className="m-0 fs-4">Clients</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}    
            />
          </div>
          <button
            onClick={handleSearch}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            {loading ? <SpinningLoader /> : "  Search"}
          </button>
          <button
              onClick={() => navigate("/clients/add-client")}
              className="custom-btn d-flex justify-content-center align-items-center gap-2"
            >
              Add Client <span className="fw-light fs-4">+</span>
            </button>
        </div>
      </section>
      {/* Top header section */}
      {/* <section className="main-content_header add-border-bottom ">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={clientsIcon1} alt="members" />
          <p className="m-0 fs-4">Clients</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div>
            {" "}
            <button
              onClick={() => navigate("/clients/add-client")}
              className="custom-btn d-flex justify-content-center align-items-center gap-2"
            >
              Add Client <span className="fw-light fs-4">+</span>
            </button>
          </div>
        </div>
      </section> */}

      {/* <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={clientsIcon1}
          headerTitle={"Clients"}
        >
          <button
            onClick={() => navigate("/clients/add-client")}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            Add Client <span className="fw-light fs-4">+</span>
          </button>
        </PageHeader>
      </div> */}

      {/* Clients Table */}
      {/* {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : clientsData.length > 0 ? (
        <ClientsTable
          tableInstance={tableInstance}
          headers={headers}
          // clientsData={clientsData}
          clientsData={csvInvoiceData}
        />
      ) : (
        <div className="mr-40 ml-30 mb-15 mt-5">
          <h5>No Clients Found, Please add new Client!</h5>
        </div>
      )} */}

      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : clientsData.length > 0 ? (
        <>
          <ClientsTable
            tableInstance={tableInstance}
            headers={headers}
            clientsData={csvInvoiceData}
          />

          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={clientsData?.length < pageSize || totalPages === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-4 mr-40 ml-30 mb-15">
          <h5>No data found!</h5>
        </div>
      )}
    </div>

    // </div>
  );
};

export default ClientsContent;
