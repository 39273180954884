import React from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const BillableEntriesTable = ({
  tableInstance,
  headers,
  billableTimeEntriesData,
  columnHeaders,
}) => {
  return (
    <section className="time-entries-table mt-4 ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} columnHeaders />
      <ReactTableFooter
        headers={headers}
        data={billableTimeEntriesData}
        tableInstance={tableInstance}
        columnHeaders={columnHeaders}
        text="billableTimeEntry"
      />
    </section>
  );
};

export default BillableEntriesTable;
