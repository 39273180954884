// import React, { useContext } from "react";

// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext"
// import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";

// const BillableTimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const shouldShowContent =
//   userDetails?.member_role === "it_member" ||
//   userDetails?.member_role === "operation_member";

//   return (
//     <>

//      {shouldShowContent && <BillableTimeEntriesContent />}

//     </>
//   );
// };

// export default BillableTimeEntries;

import React, { useContext, useState, useEffect } from "react";

import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";

const BillableTimeEntries = () => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    getAllBillableTimeEntries,
    getAllJobCategories,
    initialState,
    userDetails,
  } = useContext(ContextAPI);

  const [filters, setFilters] = useState({
    status: null,               // status (approved/pending)
    entryAs: null,              // member/team
    showDiscountedHoursEntries: false,  // Adjusted Hour checkbox
    reviewer: "",               // Reviewer name for filtering
  });
  const shouldShowContent =
    userDetails?.member_role === "it_member" ||
    userDetails?.member_role === "operation_member";

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);
  const [loading1, setLoading1] = useState(false)
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getAllJobCategories();
  //     getAllBillableTimeEntries(currentPage, pageSize);
  //   }, 100);

  //   // Cleanup function to clear the timer
  //   return () => clearTimeout(timer);
  // }, [isUpdated, currentPage, pageSize]);
  // const handleInputChange = (e) => {
  //   setSearchInput(e.target.value.trim());
  // };
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    console.log("1111")
    setSearchTriggered(false);
   
  };



  const handleSearch = () => {
    setSearchTriggered(true); 
    setCurrentPage(1); 
    
  };


 useEffect(() => {
  if (searchInput === "") {
    setSearchTriggered(false); 
    setCurrentPage(1); 
  }
}, [searchInput]);


// useEffect(() => {
//   // Only call getAllJobCategories if necessary
//   // if (searchTriggered || isUpdated) {
//   //   getAllJobCategories();
//   // }

//   // Handle billable time entries fetch based on search input and pagination
//   const fetchBillableEntries = () => {
//     if (searchInput === "" && !searchTriggered) {
//       getAllBillableTimeEntries(currentPage, pageSize, "");
//     } else if (searchTriggered && searchInput !== "") {
//       getAllBillableTimeEntries(currentPage, pageSize, searchInput);
//     }
//   };

//   fetchBillableEntries();
// }, [currentPage, pageSize, searchInput, searchTriggered, isUpdated]);

useEffect(() => {
  const fetchBillableEntries = () => {
    // If there's no search and the search has not been triggered
    if (searchInput === "" && !searchTriggered) {
      getAllBillableTimeEntries(currentPage, pageSize, "", filters);
      
    } 
    // If search input exists and search is triggered
    else if (searchTriggered && searchInput !== "") {
      getAllBillableTimeEntries(currentPage, pageSize, searchInput, filters);
    }
  };

  fetchBillableEntries();
}, [currentPage, pageSize, searchInput, searchTriggered, filters,isUpdated]);

  return (
    <>
      {shouldShowContent && (
        <BillableTimeEntriesContent
          billableTimeEntriesPropsData={initialState?.allBillableTimeEntries}
          setIsUpdated={setIsUpdated}
          isLoading={initialState?.isLoading}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleSearch={handleSearch}
          handleInputChange={handleInputChange}
          setFilters={setFilters}
          filters={filters}
          loading1={loading1}
        />
      )}
    </>
  );
};

export default BillableTimeEntries;
