import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import { format, parse, parseISO, isValid } from "date-fns";
import { EyeIconWithCircle } from "../../../../utils/ImportingImages/ImportingImages";
import styles from "./Invoice.module.css";
const MyVerticallyCenteredModal = (props) => {
  const detectAndParseDate = (dateStr) => {

    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    }

    else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }

    return null;
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N/A";

    const day = format(dateObj, "d"); 
    const month = format(dateObj, "MMM"); 
    const year = format(dateObj, "yyyy"); // Year

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };


  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Client's Jobs</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
     className="modal-body"
  
   >
       <div class={styles.gridtable}>
     <table className="table table-bordered table-hover w-100">
       <thead>
           
              <tr style={{ overflowY: "auto" }}>
                <th>Sr no</th>
                <th>Job Name</th>
                <th>Assign To</th>
                {/* <th>Assign On - Due On</th> */}
                <th>BPO No.</th>
                <th>Job Code</th>
                <th>Job Status</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(props.clientData.jobs) ? (
                props.clientData.jobs.map((item, index) => (
                  
                  <tr key={item?.jobs_id} style={{ cursor: "pointer" }}>
                    <td>{index + 1}</td>
                 
                    <td>{item.job_name}</td>
                    <td style={{ color: item.assign_to ? "inherit" : "grey" }}>
                      {item.assign_to || "N/A"}
                    </td>
                    {/* <td
                      style={{ color: item.assigned_on ? "inherit" : "grey" }}
                    >
                      {formatDate(item.assigned_on)}
                      <p>{formatDate(item.due_on)}</p>
                    </td> */}
                    <td
                      style={{
                        color: props?.clientData?.additional_data
                          ? "inherit"
                          : "grey",
                      }}
                    >
                      {props?.clientData?.additional_data
                        ? JSON.parse(props?.clientData?.additional_data).bpo_no
                        : "N/A"}
                    </td>
                    <td>{item.job_code}</td>
                    <td>
                      <Stack direction="horizontal">
                        {item.job_status === "Completed" ? (
                          <Badge bg="success">Completed</Badge>
                        ) : item.job_status === "On Hold" ? (
                          <Badge bg="danger">On Hold</Badge>
                        ) : (
                          <Badge bg="warning" text="dark">
                            In Progress
                          </Badge>
                        )}
                      </Stack>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">{props.clientData.jobs}</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>  
       
      </Modal.Body>
    </Modal>
  );
};

export const ClientWiseReportModal = ({ clientData, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <div className="">
          <EyeIconWithCircle />
          {/* <EyeIconSVG /> */}
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        clientData={clientData}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
