// import React, { useContext, useMemo, useEffect, useState } from "react";
// import { Tooltip } from "react-tooltip";

// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";

// import { fileIcon } from "../../../../../utils/ImportingImages/ImportingImages";
// import PageHeader from "../../../../../templates/PageHeader";
// import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../../../templates/Breadcrumbs";
// import JobsTable from "../../../Components/JobsTable";
// import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
// import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
// import { DeleteJobModal } from "./DeleteJobModal";
// import { EditJobModal } from "./EditJobModal";
// import { AddJobsModal } from "./AddJobsModal";

// const JobsContent = ({
//   jobsData,
//   setIsUpdated,
//   isLoading,
//   currentPage,
//   setCurrentPage,
//   pageSize,
//   totalPages,
//   setPageSize,
// }) => {
//   const userRole = localStorage.getItem("userRole");
//   const { userDetails } = useContext(ContextAPI);
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const { getAllJobCategories } = useContext(ContextAPI);

//   const [filteredJobs, setFilteredJobs] = useState(jobsData);

//   useEffect(() => {
//     getAllJobCategories();
//   }, []);

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/dashboard",
//     },
//     {
//       pageName: "Jobs",
//       pageURL: "/jobs",
//     },
//   ];

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "sr no.",
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       Header: "BPO No",
//       accessor: "bpo_no",
//     },
//     {
//       Header: "Job Name",
//       accessor: "job_name",
//     },
//     {
//       Header: "Client Name",
//       accessor: "client_name",
//     },
//     {
//       Header: "Created By",
//       accessor: "job_added_by",
//     },
//     {
//       Header: "Category",
//       accessor: "job_category",
//     },

//     // ...(userRole !== "members,team_sub_leader" &&
//     // userRole !== "team_leaders,members"
//     //   ? [
//     {
//       Header: "Edit",
//       Cell: ({ row }) => (
//         <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
//           <Tooltip
//             id="edit-job-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="edit-job-tooltip"
//             data-tooltip-content="Edit Job Details"
//             data-tooltip-place="top"
//           >
//             <EditJobModal jobData={row.original} setIsUpdated={setIsUpdated} />
//           </div>

//           <Tooltip
//             id="delete-job-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="delete-job-tooltip"
//             data-tooltip-content="Delete Job"
//             data-tooltip-place="top"
//           >
//             <DeleteJobModal
//               jobData={row.original}
//               setIsUpdated={setIsUpdated}
//             />
//           </div>
//         </div>
//       ),
//     },
//     //   ]
//     // : []),
//   ];

//   const columnHeaders = [
//     "Sr no.",
//     "Job Code",
//     "Job Name",
//     "Client Name",
//     "Created By",
//     "Category",
//     "Edit",
//   ];

//   useEffect(() => {
//     if (
//       userDetails?.member_role === "members,team_sub_leader" ||
//       userDetails?.member_role === "team_leaders,members"
//     ) {
//       // const filtered =
//       //   jobsData?.filter((e) => e.job_added_by === userDetails?.member_name) ||
//       //   [];
//       // console.log("filtered", filtered);
//       // setFilteredJobs(filtered);
//       setFilteredJobs(jobsData);
//     } else {
//       setFilteredJobs([]); // Optionally clear filteredJobs if role does not match
//     }
//   }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

//   // useEffect(() => {
//   //   const uniqueJobs = [];
//   //   const jobIds = new Set();

//   //   jobsData.forEach((job) => {
//   //     if (!jobIds.has(job.job_id)) {
//   //       jobIds.add(job.job_id);
//   //       uniqueJobs.push(job);
//   //     }
//   //   });

//   //   if (
//   //     userDetails?.member_role === "members,team_sub_leader" ||
//   //     userDetails?.member_role === "members" ||
//   //     userDetails?.member_role === "team_leaders,members"
//   //   ) {
//   //     const filtered =
//   //       uniqueJobs.filter((e) => e.job_added_by === userDetails?.member_name) || [];
//   //     setFilteredJobs(filtered);
//   //   } else {
//   //     setFilteredJobs(uniqueJobs);
//   //   }
//   // }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

//   const columns = useMemo(() => tableColumns, []);
//   // const data = useMemo(() => jobsData, [jobsData]);

//   const data = useMemo(() => {
//     return userDetails?.member_role === "members,team_sub_leader" ||
//       userDetails?.member_role === "members" ||
//       userDetails?.member_role === "team_leaders,members"
//       ? filteredJobs
//       : jobsData;
//   }, [filteredJobs, jobsData, userDetails?.member_role]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     localStorage.setItem("currentPage", page); // Save the page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch
//   };

//   const handlePageSizeChange = (event) => {
//     const newSize = Number(event.target.value);
//     setPageSize(newSize); // Update the component's state
//     tableInstance.setPageSize(newSize); // Update the React Table's internal state
//     setCurrentPage(1); // Reset to the first page
//     localStorage.setItem("pageSize", newSize); // Save the page size
//     localStorage.setItem("currentPage", 1); // Save the new page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
//   };

//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   const headers = {
//     headings: [
//       { label: "Job Code", key: "job_code" },
//       { label: "BPO No", key: "bpo_no" },
//       { label: "Job Name", key: "job_name" },
//       { label: "Client Name", key: "client_name" },
//       { label: "Job added by", key: "job_added_by" },
//       { label: "Job Category", key: "job_category" },
//       { label: "Job added on", key: "job_add_on" },
//     ],
//     fileName: "Jobs",
//   };

//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       <div className="relative-wrapper zIndex-2">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={fileIcon}
//           headerTitle={"All Jobs"}
//         >
//           <AddJobsModal setIsUpdated={setIsUpdated} />
//         </PageHeader>
//       </div>

//       {/* Jobs Table */}
//       <div className="mt-5">
//         {isLoading ? (
//           <ReactTableSkeleton columnHeaders={columnHeaders} />
//         ) : jobsData?.length > 0 ? (
//           <>
//             <JobsTable
//               tableInstance={tableInstance}
//               headers={headers}
//               jobData={jobsData}
//             />
//             <div
//               className="d-flex justfy-content-center align-items-center gap-3 smallText"
//               style={{
//                 width: "45%",
//                 justifyContent: "space-between",
//                 position: "absolute",
//                 marginLeft: "90px",
//                 bottom: "48px",
//               }}
//             >
//               <div className="d-flex gap-1 align-items-center">
//                 <button
//                   className="cursor-pointer"
//                   style={{ border: "none", background: "transparent" }}
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="24"
//                     height="24"
//                     viewBox="0 0 24 24"
//                   >
//                     <path
//                       fill="currentColor"
//                       d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
//                     />
//                   </svg>
//                 </button>
//                 <input
//                   className="gotoPage-input-field"
//                   type="number"
//                   defaultValue={currentPage}
//                   readOnly
//                 />
//                 <span className="px-2">/</span>
//                 <span>{totalPages}</span>
//                 <button
//                   style={{ border: "none", background: "transparent" }}
//                   className="cursor-pointer"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={jobsData?.length < pageSize || totalPages === 1}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="24"
//                     height="24"
//                     viewBox="0 0 24 24"
//                   >
//                     <path
//                       fill="currentColor"
//                       d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
//                     />
//                   </svg>
//                 </button>
//               </div>
//               <div className="d-flex justify-content-center align-items-center">
//                 <span className="px-1 smallText">Rows /page</span>
//                 <select value={pageSize} onChange={handlePageSizeChange}>
//                   <option value={10}>10</option>
//                   <option value={25}>25</option>
//                   <option value={50}>50</option>
//                   <option value={100}>100</option>
//                 </select>
//               </div>
//             </div>
//           </>
//         ) : (
//           <div className="mr-40 ml-30 mb-15">
//             <h5>No Projects Found, Please create new Project!</h5>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default JobsContent;


import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { fileIcon } from "../../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import JobsTable from "../../../Components/JobsTable";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import { DeleteJobModal } from "./DeleteJobModal";
import { EditJobModal } from "./EditJobModal";
import { AddJobsModal } from "./AddJobsModal";

const JobsContent = ({
  jobsData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
}) => {
  const userRole = localStorage.getItem("userRole");
  const { userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getAllJobCategories } = useContext(ContextAPI);

  const [filteredJobs, setFilteredJobs] = useState(jobsData);

  useEffect(() => {
    getAllJobCategories();
  }, []);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Jobs",
      pageURL: "/jobs",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "BPO No",
      accessor: "bpo_no",
    },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Created By",
      accessor: "job_added_by",
    },
    {
      Header: "Category",
      accessor: "job_category",
    },

    // ...(userRole !== "members,team_sub_leader" &&
    // userRole !== "team_leaders,members"
    //   ? [
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-job-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-job-tooltip"
            data-tooltip-content="Edit Job Details"
            data-tooltip-place="top"
          >
            <EditJobModal jobData={row.original} setIsUpdated={setIsUpdated} />
          </div>

          <Tooltip
            id="delete-job-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-job-tooltip"
            data-tooltip-content="Delete Job"
            data-tooltip-place="top"
          >
            <DeleteJobModal
              jobData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
    //   ]
    // : []),
  ];

  const columnHeaders = [
    "Sr no.",
    "Job Code",
    "Job Name",
    "Client Name",
    "Created By",
    "Category",
    "Edit",
  ];

  useEffect(() => {
    if (
      userDetails?.member_role === "members,team_sub_leader" ||
      userDetails?.member_role === "team_leaders,members"
    ) {
      // const filtered =
      //   jobsData?.filter((e) => e.job_added_by === userDetails?.member_name) ||
      //   [];
      // console.log("filtered", filtered);
      // setFilteredJobs(filtered);
      setFilteredJobs(jobsData);
    } else {
      setFilteredJobs([]); // Optionally clear filteredJobs if role does not match
    }
  }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

  // useEffect(() => {
  //   const uniqueJobs = [];
  //   const jobIds = new Set();

  //   jobsData.forEach((job) => {
  //     if (!jobIds.has(job.job_id)) {
  //       jobIds.add(job.job_id);
  //       uniqueJobs.push(job);
  //     }
  //   });

  //   if (
  //     userDetails?.member_role === "members,team_sub_leader" ||
  //     userDetails?.member_role === "members" ||
  //     userDetails?.member_role === "team_leaders,members"
  //   ) {
  //     const filtered =
  //       uniqueJobs.filter((e) => e.job_added_by === userDetails?.member_name) || [];
  //     setFilteredJobs(filtered);
  //   } else {
  //     setFilteredJobs(uniqueJobs);
  //   }
  // }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => jobsData, [jobsData]);

  const data = useMemo(() => {
    return userDetails?.member_role === "members,team_sub_leader" ||
      userDetails?.member_role === "members" ||
      userDetails?.member_role === "team_leaders,members"
      ? filteredJobs
      : jobsData;
  }, [filteredJobs, jobsData, userDetails?.member_role]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headers = {
    headings: [
      { label: "Job Code", key: "job_code" },
      { label: "BPO No", key: "bpo_no" },
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Job added by", key: "job_added_by" },
      { label: "Job Category", key: "job_category" },
      { label: "Job added on", key: "job_add_on" },
    ],
    fileName: "Jobs",
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* {/ Top header section /} */}
      <div className="relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={fileIcon}
          headerTitle={"All Jobs"}
        >
          <AddJobsModal setIsUpdated={setIsUpdated} />
        </PageHeader>
      </div>

      {/* {/ Jobs Table /} */}
      <div className="mt-5">
        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : jobsData?.length > 0 ? (
          <>
            <JobsTable
              tableInstance={tableInstance}
              headers={headers}
              jobData={jobsData}
            />
            <div
              className="d-flex justfy-content-center align-items-center gap-3 smallText"
              style={{
                width: "45%",
                justifyContent: "space-between",
                position: "absolute",
                marginLeft: "90px",
                bottom: "48px",
              }}
            >
              <div className="d-flex gap-1 align-items-center">
                <button
                  className="cursor-pointer"
                  style={{ border: "none", background: "transparent" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                    />
                  </svg>
                </button>
                <input
                  className="gotoPage-input-field"
                  type="number"
                  defaultValue={currentPage}
                  readOnly
                />
                <span className="px-2">/</span>
                <span>{totalPages}</span>
                <button
                  style={{ border: "none", background: "transparent" }}
                  className="cursor-pointer"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={jobsData?.length < pageSize || totalPages === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="px-1 smallText">Rows /page</span>
                <select value={pageSize} onChange={handlePageSizeChange}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </>
        ) : (
          <div className="mr-40 ml-30 mb-15">
            <h5>No Projects Found, Please create new Project!</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobsContent;
