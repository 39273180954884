// import React, { useContext, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
// import { Tooltip } from "react-tooltip";

// import PageHeader from "../../../../templates/PageHeader";
// import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../../templates/Breadcrumbs";
// import TeamsTable from "../../Components/TeamsTable";
// import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
// import { DeleteTeamModal } from "./DeleteTeamModal";

// import {
//   EditSVG,
//   teamsIcon1,
// } from "../../../../utils/ImportingImages/ImportingImages";

// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";

// const TeamsContent = ({ teamsData, setIsUpdated, isLoading }) => {
//   const navigate = useNavigate();
//   const { sidebarClose } = useContext(ContextSidebarToggler);

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/dashboard",
//     },
//     {
//       pageName: "Teams",
//       pageURL: "/teams",
//     },
//   ];

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "sr no.",
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       Header: "Team Code",
//       accessor: "team_code",
//     },
//     {
//       Header: "Team Name",
//       accessor: "team_name",
//     },
//     {
//       Header: "Team Leader",
//       accessor: "leader_name",
//       Cell: ({ row }) => {
//         const firstLetter = row.original.leader_name
//           ? row.original.leader_name.slice(0, 1).toUpperCase()
//           : "NA";
//         return (
//           firstLetter +
//           (row.original.leader_name ? row.original.leader_name.slice(1) : "")
//         );
//       },
//     },

//     {
//       Header: "Members Count",
//       accessor: "member_count",
//       Cell: ({ row }) => +row.original.member_count,
//     },
//     {
//       Header: "Status",
//       accessor: "status",
//       Cell: ({ row }) => {
//         return row.original.status === "active"
//           ? "Active"
//           : row.original.status === "inactive"
//           ? "Suspended"
//           : "";
//       },
//     },
//     {
//       Header: "Edit",
//       Cell: ({ row }) => (
//         <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
//           <Tooltip
//             id="edit-team-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="edit-team-tooltip"
//             data-tooltip-content="Edit Team"
//             data-tooltip-place="top"
//             onClick={() =>
//               navigate(`/teams/manage-team/${row?.original?.id}`, {
//                 state: {
//                   teamData: row?.original,
//                 },
//               })
//             }
//           >
//             <EditSVG />
//           </div>
//           <Tooltip
//             id="delete-team-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="delete-team-tooltip"
//             data-tooltip-content="Delete Team"
//             data-tooltip-place="top"
//           >
//             <DeleteTeamModal
//               teamData={row.original}
//               setIsUpdated={setIsUpdated}
//             />
//           </div>
//         </div>
//       ),
//     },
//   ];

//   const columnHeaders = [
//     "Sr no",
//     "Team Code",
//     "Team Name",
//     "Team Leader",
//     "Team Members Names",
//     "Team Members Count",
//     "Status",
//     "Edit",
//   ];

//   const columns = useMemo(() => tableColumns, []);
//   const data = useMemo(() => teamsData, [teamsData]);

//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   // constructing headers for CSV Link

//   const headers = {
//     headings: [
//       { label: "Team Code", key: "team_code" },
//       { label: "Team Name", key: "team_name" },
//       { label: "Team Leader", key: "leader_name" },
//       { label: "Team Members Names", key: "member_names" },
//       { label: "Team Members Count", key: "member_count" },
//       { label: "Team Status", key: "status" },
//     ],
//     fileName: "Teams List",
//   };
//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       <div className="mb-5">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={teamsIcon1}
//           headerTitle={"All Teams"}
//         >
//           <button
//             onClick={() => navigate("/teams/add-team")}
//             className="custom-btn d-flex justify-content-center align-items-center gap-2"
//           >
//             Add Team <span className="fw-light fs-4">+</span>
//           </button>
//         </PageHeader>
//       </div>

//       {/* Members Table */}
//       {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : teamsData.length > 0 ? (
//         <TeamsTable
//           tableInstance={tableInstance}
//           headers={headers}
//           teamsData={teamsData}
//         />
//       ) : (
//         <div className="mr-40 ml-30 mb-15">
//           <h5>No Teams Found, Please create new Team!</h5>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TeamsContent;

// import React, { useContext, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Tooltip } from "react-tooltip";

// import PageHeader from "../../../../templates/PageHeader";
// import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../../templates/Breadcrumbs";
// import TeamsTable from "../../Components/TeamsTable";
// import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
// import { DeleteTeamModal } from "./DeleteTeamModal";
// import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
// import {
//   EditSVG,
//   teamsIcon1,
//   searchIcon,
// } from "../../../../utils/ImportingImages/ImportingImages";

// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";

// const TeamsContent = ({
//   teamsData,
//   setIsUpdated,
//   isLoading,
//   currentPage,
//   setCurrentPage,
//   searchInput,
//   setSearchInput,
//   pageSize,
//   totalPages,
//   setPageSize,
//   handleSearch,
//   handleInputChange,
// }) => {
//   const navigate = useNavigate();
//   const { sidebarClose } = useContext(ContextSidebarToggler);

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/dashboard",
//     },
//     {
//       pageName: "Teams",
//       pageURL: "/teams",
//     },
//   ];
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     localStorage.setItem("currentPage", page); // Save the page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch
//   };
//   const [loading, setLoading] = useState(false);
//   const handlePageSizeChange = (event) => {
//     const newSize = Number(event.target.value);
//     setPageSize(newSize); // Update the component's state
//     tableInstance.setPageSize(newSize); // Update the React Table's internal state
//     setCurrentPage(1); // Reset to the first page
//     localStorage.setItem("pageSize", newSize); // Save the page size
//     localStorage.setItem("currentPage", 1); // Save the new page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
//   };

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "sr no.",
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       Header: "Team Code",
//       accessor: "team_code",
//     },
//     {
//       Header: "Team Name",
//       accessor: "team_name",
//     },
//     {
//       Header: "Team Leader",
//       accessor: "leader_name",
//       Cell: ({ row }) => {
//         const firstLetter = row.original.leader_name
//           ? row.original.leader_name.slice(0, 1).toUpperCase()
//           : "NA";
//         return (
//           firstLetter +
//           (row.original.leader_name ? row.original.leader_name.slice(1) : "")
//         );
//       },
//     },

//     {
//       Header: "Members Count",
//       accessor: "member_count",
//       Cell: ({ row }) => +row.original.member_count,
//     },
//     {
//       Header: "Status",
//       accessor: "status",
//       Cell: ({ row }) => {
//         return row.original.status === "active"
//           ? "Active"
//           : row.original.status === "inactive"
//           ? "Suspended"
//           : "";
//       },
//     },
//     {
//       Header: "Edit",
//       Cell: ({ row }) => (
//         <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
//           <Tooltip
//             id="edit-team-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="edit-team-tooltip"
//             data-tooltip-content="Edit Team"
//             data-tooltip-place="top"
//             onClick={() =>
//               navigate(`/teams/manage-team/${row?.original?.id}`, {
//                 state: {
//                   teamData: row?.original,
//                 },
//               })
//             }
//           >
//             <EditSVG />
//           </div>
//           <Tooltip
//             id="delete-team-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             data-tooltip-id="delete-team-tooltip"
//             data-tooltip-content="Delete Team"
//             data-tooltip-place="top"
//           >
//             <DeleteTeamModal
//               teamData={row.original}
//               setIsUpdated={setIsUpdated}
//             />
//           </div>
//         </div>
//       ),
//     },
//   ];

//   const columnHeaders = [
//     "Sr no",
//     "Team Code",
//     "Team Name",
//     "Team Leader",
//     "Team Members Names",
//     "Team Members Count",
//     "Status",
//     "Edit",
//   ];

//   const columns = useMemo(() => tableColumns, []);
//   const data = useMemo(() => teamsData, [teamsData]);
//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   // constructing headers for CSV Link

//   const headers = {
//     headings: [
//       { label: "Team Code", key: "team_code" },
//       { label: "Team Name", key: "team_name" },
//       { label: "Team Leader", key: "leader_name" },
//       { label: "Team Members Names", key: "member_names" },
//       { label: "Team Members Count", key: "member_count" },
//       { label: "Team Status", key: "status" },
//     ],
//     fileName: "Teams List",
//   };
//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       {/* <div className="mb-5">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={teamsIcon1}
//           headerTitle={"All Teams"}
//         >
//           <button
//             onClick={() => navigate("/teams/add-team")}
//             className="custom-btn d-flex justify-content-center align-items-center gap-2"
//           >
//             Add Team <span className="fw-light fs-4">+</span>
//           </button>
//         </PageHeader>
//       </div> */}

//       <section className="main-content_header add-border-bottom">
//         <div className="d-flex justify-content-center align-items-center page-heading">
//           <img src={teamsIcon1} alt="teams" />
//           <p className="m-0 fs-4">All Teams</p>
//         </div>
//         <div className="d-flex justify-content-center align-items-center gap-3">
//           <div className="relative-wrapper">
//             <img className="search-icon" src={searchIcon} alt="search-icon" />
//             <input
//               className="input-field"
//               type="text"
//               placeholder="Search"
//               value={searchInput}
//               onChange={handleInputChange}
//             />
//           </div>
//           <button
//             onClick={handleSearch}
//             className="custom-btn d-flex justify-content-center align-items-center gap-2"
//           >
//             {loading ? <SpinningLoader /> : "  Search"}
//           </button>
//           <button
//             onClick={() => navigate("/teams/add-team")}
//             className="custom-btn d-flex justify-content-center align-items-center gap-2"
//           >
//             Add Team <span className="fw-light fs-4">+</span>
//           </button>
//         </div>
//       </section>
//       {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : teamsData.length > 0 ? (
//         <>
//           <TeamsTable
//             tableInstance={tableInstance}
//             headers={headers}
//             teamsData={teamsData}
//           />

//           <div
//             className="d-flex justfy-content-center align-items-center gap-3 smallText"
//             style={{
//               width: "45%",
//               justifyContent: "space-between",
//               position: "absolute",
//               marginLeft: "90px",
//               bottom: "48px",
//             }}
//           >
//             <div className="d-flex gap-1 align-items-center">
//               <button
//                 className="cursor-pointer"
//                 style={{ border: "none", background: "transparent" }}
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
//                   />
//                 </svg>
//               </button>
//               <input
//                 className="gotoPage-input-field"
//                 type="number"
//                 defaultValue={currentPage}
//                 readOnly
//               />
//               <span className="px-2">/</span>

//               <span> {totalPages}</span>
//               <button
//                 style={{ border: "none", background: "transparent" }}
//                 className="cursor-pointer"
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={teamsData?.length < pageSize || totalPages === 1}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
//                   />
//                 </svg>
//               </button>
//             </div>
//             <div className="d-flex justify-content-center align-items-center">
//               <span className="px-1 smallText">Rows /page</span>
//               <select value={pageSize} onChange={handlePageSizeChange}>
//                 <option value={10}>10</option>
//                 <option value={25}>25</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//               </select>
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="mt-4 mr-40 ml-30 mb-15">
//           <h5>No data found!</h5>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TeamsContent;

import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import PageHeader from "../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import TeamsTable from "../../Components/TeamsTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import { DeleteTeamModal } from "./DeleteTeamModal";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import {
  EditSVG,
  teamsIcon1,
  searchIcon,
} from "../../../../utils/ImportingImages/ImportingImages";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

const TeamsContent = ({
  teamsData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  searchInput,
  setSearchInput,
  pageSize,
  totalPages,
  setPageSize,
  handleSearch,
  handleInputChange,
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Teams",
      pageURL: "/teams",
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };
  const [loading, setLoading] = useState(false);
  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };
  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Team Code",
      accessor: "team_code",
    },
    {
      Header: "Team Name",
      accessor: "team_name",
    },
    {
      Header: "Team Leader",
      accessor: "leader_name",
      Cell: ({ row }) => {
        const firstLetter = row.original.leader_name
          ? row.original.leader_name.slice(0, 1).toUpperCase()
          : "NA";
        return (
          firstLetter +
          (row.original.leader_name ? row.original.leader_name.slice(1) : "")
        );
      },
    },

    {
      Header: "Members Count",
      accessor: "member_count",
      Cell: ({ row }) => +row.original.member_count,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return row.original.status === "active"
          ? "Active"
          : row.original.status === "inactive"
          ? "Suspended"
          : "";
      },
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-team-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-team-tooltip"
            data-tooltip-content="Edit Team"
            data-tooltip-place="top"
            onClick={() =>
              navigate(`/teams/manage-team/${row?.original?.id}`, {
                state: {
                  teamData: row?.original,
                },
              })
            }
          >
            <EditSVG />
          </div>
          <Tooltip
            id="delete-team-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-team-tooltip"
            data-tooltip-content="Delete Team"
            data-tooltip-place="top"
          >
            <DeleteTeamModal
              teamData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no",
    "Team Code",
    "Team Name",
    "Team Leader",
    "Team Members Names",
    "Team Members Count",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => teamsData, [teamsData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // constructing headers for CSV Link

  const headers = {
    headings: [
      { label: "Team Code", key: "team_code" },
      { label: "Team Name", key: "team_name" },
      { label: "Team Leader", key: "leader_name" },
      { label: "Team Members Names", key: "member_names" },
      { label: "Team Members Count", key: "member_count" },
      { label: "Team Status", key: "status" },
    ],
    fileName: "Teams List",
  };
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* {/ Top header section /} */}
      {/* <div className="mb-5">
        <PageHeader
          tableInstance={tableInstance}
          icon={teamsIcon1}
          headerTitle={"All Teams"}
        >
          <button
            onClick={() => navigate("/teams/add-team")}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            Add Team <span className="fw-light fs-4">+</span>
          </button>
        </PageHeader>
      </div> */}

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={teamsIcon1} alt="teams" />
          <p className="m-0 fs-4">All Teams</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
          <button
            onClick={handleSearch}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            {loading ? <SpinningLoader /> : "  Search"}
          </button>
          <button
            onClick={() => navigate("/teams/add-team")}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            Add Team <span className="fw-light fs-4">+</span>
          </button>
        </div>
      </section>
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : teamsData.length > 0 ? (
        <>
          <TeamsTable
            tableInstance={tableInstance}
            headers={headers}
            teamsData={teamsData}
          />

          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={teamsData?.length < pageSize || totalPages === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-4 mr-40 ml-30 mb-15">
          <h5>No data found!</h5>
        </div>
      )}
    </div>
  );
};

export default TeamsContent;
